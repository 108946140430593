import React from 'react';
import { Form, FormGroup, Button, FormInput } from 'shards-react';
import { AuthContext } from '../../contexts/AuthContext.js';

export default class UserForm extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const { id, phoneNumber } = props.user || {};

    this.state = {
      id,
      phoneNumber,
      submitting: false
    };
  }

  render() {
    const { id, phoneNumber } = this.state;

    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.setState({ submitting: true });
          this.props
            .mutate({ variables: { id, phoneNumber } })
            .then((response) => this.props.onSubmit(response.data.account))
            .catch((error) => this.props.onError(error))
            .finally(() => this.setState({ submitting: false }));
        }}
      >
        <FormGroup>
          <label htmlFor="phoneNumber">Teléfono</label>
          <FormInput
            value={this.state.phoneNumber}
            id="phoneNumber"
            onChange={(event) => {
              this.setState({ phoneNumber: event.target.value });
            }}
            required
          />
        </FormGroup>

        <Button disabled={this.state.submitting} type="submit">
          Actualizar Usuario
        </Button>
      </Form>
    );
  }
}
