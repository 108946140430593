import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';
import SubscriptionFragments from '../queries/fragments/SubscriptionFragments';

export default function MutationActivateSubscription({ id, children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation ActivateSubscription($id: String!) {
          subscription: activateSubscription(id: $id) {
            ...SubscriptionFields
          }
        }
        ${SubscriptionFragments.fields}
      `}
      variables={{ id: id }}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
