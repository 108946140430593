import React from 'react';

export default function TruncatedEmail(attribute) {
  return function({ value }) {
    const email = attribute && value ? value[attribute] : value;
    const truncatedEmail = (email || '').replace(/@.*/, '@...');

    return <td title={email}>{truncatedEmail ? truncatedEmail : '-'}</td>;
  };
}
