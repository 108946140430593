import React from 'react';
import styled from 'styled-components';
import { FormSelect } from 'shards-react';

function SearchSelect(props) {
  const { options, label, valueKey = 'value', labelKey = 'label', ...rest } = props;

  return (
    <FormSelect {...rest}>
      <option selected={!rest.value} value="">
        -- {label} --
      </option>
      {(options || []).map((option) => (
        <option key={option[valueKey]} value={option[valueKey]}>
          {option[labelKey]}
        </option>
      ))}
    </FormSelect>
  );
}

export default styled(SearchSelect)`
  min-width: 140px;
  width: 240px;
  margin-right: 20px;
`;
