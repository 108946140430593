import React, { useState } from 'react';
import { Modal } from 'shards-react';

export default function ModalAction({ label, content: Component, data, refetch, size }) {
  const onClick = (event) => {
    event.preventDefault();
    toggle();
  };

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((currentValue) => !currentValue);
  };

  return (
    <td>
      <a href="/" onClick={onClick}>
        {label}
      </a>
      <Modal open={open} toggle={toggle} size={size}>
        <Component data={data} toggle={toggle} refetch={refetch} />
      </Modal>
    </td>
  );
}
