import React from 'react';
import { CLOSE_REASONS } from '../../edit/CancelLicensesForm';

export default function SubscriptionCancelMotiveField({ value }) {
  if (!value || !value.motive) {
    return <td></td>;
  }

  return <td>{CLOSE_REASONS[value.motive] || value.motive}</td>;
}
