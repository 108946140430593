import React, { Fragment, useEffect } from 'react';
import { FormInput, FormSelect } from 'shards-react';

const TextInput = ({ attributes, ...props }) => {
  return <FormInput type="text" {...props} />;
};

const SelectInput = ({ attributes, onChange, ...props }) => {
  const { options = [] } = attributes || {};

  // initialize state with the first option available
  useEffect(() => {
    if (options.length > 0) {
      onChange({ target: { value: options[0].value } });
    }
  }, [options, onChange]);

  return (
    <FormSelect {...props} onChange={onChange}>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </FormSelect>
  );
};

export default function CustomField({ field, onChange, value }) {
  // By default custom fields are input with type text
  const Input = field.inputType === 'select' ? SelectInput : TextInput;

  const required = field.required ? '*' : '';

  return (
    <Fragment>
      <label htmlFor={field.name}>
        {field.label}
        {required}
      </label>
      <Input
        required={false && field.required}
        id={field.name}
        onChange={onChange}
        attributes={field.attributes}
        value={value}
      />
    </Fragment>
  );
}
