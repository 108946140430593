import React from 'react';
import { Link } from 'react-router-dom';

export default function LinkToModelField(path) {
  return function buildLinkToModelComponent({ value }) {
    return (
      <td>
        <Link to={`${path}/${value}`}>{value}</Link>
      </td>
    );
  };
}
