import React, { useState, useEffect } from 'react';
import { Form, FormGroup, FormSelect, Button } from 'shards-react';

export default function ChangeSubscription({
  subscriptions,
  accountId,
  mutate,
  products,
  ...props
}) {
  const [productSubscription, setProductSubscription] = useState(undefined);
  const [country, setCountry] = useState('');
  const [buttonLabel, setButtonLabel] = useState('Crear subscripcion');
  const [productsWithEnabled, setProductsWithEnabled] = useState([]);

  useEffect(() => {
    if (!products) return;
    const auxProductsWithEnabled = products
      .filter((product) => !product.isFree)
      .map((product) => ({
        product,
        enabled: subscriptions.some(
          (subscription) =>
            subscription.state !== 'canceled' && subscription.product.id === product.id
        )
      }));
    setProductsWithEnabled(auxProductsWithEnabled);
  }, [subscriptions, products]);

  if (!products) return null;

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        mutate({
          variables: {
            accountId: accountId,
            productId: productSubscription.id,
            country: country
          }
        })
          .then((subscription) => {
            setButtonLabel('Crear otra subscripcion');
            props.onSuccess(subscription);
            props.refetch();
          })
          .catch((error) => {
            props.onError(error);
          });
      }}
    >
      <FormGroup>
        <label>Nueva subscripcion</label>
        <br />
        <label htmlFor="productSubscription">Producto</label>
        <FormSelect
          id="productSubscription"
          defaultValue=""
          onChange={(event) =>
            setProductSubscription(products.find((product) => product.id === event.target.value))
          }
          required
        >
          <option value="" disabled>
            Seleccione un producto.
          </option>
          {productsWithEnabled.map(({ product, enabled }) => (
            <option value={product.id} disabled={enabled}>
              {`${product.name} ${enabled ? '\u2713' : ''}`}
            </option>
          ))}
        </FormSelect>
        <label htmlFor="country">Pais</label>
        <FormSelect
          id="country"
          defaultValue=""
          onChange={(event) => setCountry(event.target.value)}
          required
        >
          <option value="" disabled>
            Seleccione un pais de los disponibles para el producto.
          </option>
          {productSubscription &&
            productSubscription.countries.map((country) => (
              <option value={country}>{country}</option>
            ))}
        </FormSelect>
      </FormGroup>
      <Button type="submit">{buttonLabel}</Button>
    </Form>
  );
}
