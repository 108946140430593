import React from 'react';
import { ButtonGroup, Button } from 'shards-react';
import { ArrowLeftIcon, ArrowRightIcon } from '@increasecard/icons';

export default function Paginate({ currentPage, onPageChange }) {
  return (
    <React.Fragment>
      <ButtonGroup className="ml-2 mr-2">
        {currentPage > 1 && (
          <Button onClick={() => onPageChange(-1)}>
            <ArrowLeftIcon height="24" width="24" /> Anterior
          </Button>
        )}
        <Button onClick={() => onPageChange(1)}>
          Siguiente <ArrowRightIcon height="24" width="24" />
        </Button>
      </ButtonGroup>
    </React.Fragment>
  );
}
