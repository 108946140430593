import React from 'react';
import { Badge } from 'shards-react';

export default function TaskResolution({ value }) {
  return (
    <td align="center" title={value ? value.name : ''}>
      <Badge theme={value ? 'success' : 'secondary'}>{value ? 'Si' : 'No'}</Badge>
    </td>
  );
}
