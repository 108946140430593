import React from 'react';
import { Badge } from 'shards-react';

export default function InvitationAccepted({ value: invitation }) {
  let value = '';

  if (invitation && invitation.acceptedAt) {
    value = new Date(invitation.acceptedAt).toLocaleString();
  } else if (invitation && invitation.acceptedByOther) {
    value = <Badge theme="info">Aceptada por otro partner</Badge>;
  }

  return <td>{value}</td>;
}
