import React from 'react';
import { ModalBody, ModalHeader } from 'shards-react';

const Modal = ({ tittle, children }) => {
  if (!children) return;

  return (
    <>
      <ModalHeader>{tittle}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </>
  );
};

export default Modal;
