import ListTextField from '../../components/fields/list/TextField';
import ShowTextField from '../../components/fields/show/TextField';
import ShowDateField from '../../components/fields/show/DateField';
import ShowPaymentTypeField from '../../components/fields/show/PaymentTypeField';
import ShowPaymentStatusField from '../../components/fields/show/PaymentStatusField';
import AttributeField from '../../components/fields/list/AttributeField';
import ListDateField from '../../components/fields/list/DateField';
import ListSubscriptionStateField from '../../components/fields/list/SubscriptionStateField';
import TableBooleanField from '../../components/fields/list/TableBooleanField';
import UserNameField from '../../components/fields/list/UserNameField';
import BooleanField from '../../components/fields/show/BooleanField';

const accountFields = {
  list: {
    name: { label: 'Nombre', content: ListTextField },
    taxId: { label: 'Tax ID', content: ListTextField },
    createdAt: { label: 'Fecha de creación', content: ListDateField }
  },
  show: {
    id: { label: 'ID', content: ShowTextField },
    name: { label: 'Nombre', content: ShowTextField },
    taxId: { label: 'Tax ID', content: ShowTextField },
    createdAt: { label: 'Fecha de creación', content: ShowDateField },
    users: {
      label: 'Usuarios',
      type: 'nested',
      links: true,
      editable: true,
      path: '/users',
      content: {
        id: { label: 'ID', content: ShowTextField },
        email: { label: 'Email', content: ShowTextField },
        name: { label: 'Nombre', content: UserNameField, virtual: true },
        owner: { label: 'Dueño', content: TableBooleanField },
        phoneNumber: { label: 'Telefono', content: ShowTextField }
      }
    },
    subscriptions: {
      label: 'Subscripciones',
      type: 'nested',
      path: '/subscriptions',
      links: true,
      content: {
        product: { label: 'Producto', content: AttributeField('name') },
        state: { label: 'Estado', content: ListSubscriptionStateField },
        terminalsCount: { label: 'Terminales', content: ShowTextField },
        country: { label: 'Pais', content: ShowTextField }
      }
    },
    payments: {
      label: 'Pagos',
      type: 'nested',
      links: false,
      content: {
        orderDate: { label: 'Fecha', content: ShowDateField },
        type: { label: 'Tipo', content: ShowPaymentTypeField },
        status: { label: 'Estado', content: ShowPaymentStatusField },
        country: { label: 'País', content: ShowTextField },
        amount: { label: 'Cantidad', content: ShowTextField }
      }
    },
    invoices: {
      label: 'Facturas',
      type: 'nested',
      links: false,
      content: {
        emissionDate: { label: 'Emisión', content: ListDateField },
        amount: {
          label: 'Monto',
          content: ListTextField,
          permission: 'acc:show:invoice-amount'
        },
        taxAmount: {
          label: 'Impuestos',
          content: ListTextField,
          permission: 'acc:show:invoice-tax-amount'
        },
        country: { label: 'País', content: ListTextField },
        status: { label: 'Estado', content: ListTextField },
        expirationDate: {
          label: 'Expiración',
          content: ListDateField,
          permission: 'acc:show:invoice-expiration-date'
        },
        externalDocumentLink: {
          label: 'Link externo',
          content: ListTextField,
          roles: 'acc:show:invoice-external-link'
        }
      }
    },
    tasks: {
      label: 'Tareas',
      type: 'nested',
      links: false,
      content: {
        priority: { label: 'Prioridad', content: AttributeField('name') },
        state: { label: 'Estado', content: AttributeField('name') },
        creator: { label: 'Creador', content: AttributeField('email') },
        assignedTo: { label: 'Asignado', content: AttributeField('email') },
        dueAt: { label: 'Vencimiento', content: ListDateField }
      }
    },
    accountBillingStates: {
      label: 'Estado facturación',
      type: 'nested',
      links: false,
      content: {
        country: { label: 'País', content: ShowTextField },
        balance: { label: 'Balance', content: ShowTextField },
        inDebtSince: { label: 'En deuda desde', content: ShowTextField }
      }
    },
    manualScraper: { label: 'Scraper Manual', content: BooleanField },
    enterprise: { label: 'Enterprise', content: BooleanField },
    comments: {
      label: 'Comentarios',
      type: 'nested',
      content: {
        writerEmail: { label: 'Escritor', content: ShowTextField },
        body: { label: 'Comentario', content: ShowTextField },
        productName: { label: 'Producto', content: ShowTextField },
        createdAt: { label: 'Fecha', content: ShowDateField }
      }
    }
  },
  search: [
    { key: 'partnerId', label: 'Partner', type: 'partner', permission: 'acc:search:partner' },
    {
      key: 'salesOwnerId',
      label: 'Vendedor',
      type: 'users',
      permission: 'acc:search:sales-owner'
    },
    { key: 'name', label: 'Nombre' },
    { key: 'taxId', label: 'CUIT' }
  ]
};

export default accountFields;
