import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

export default function ModelLink({ value: task }) {
  const { taskableType } = task;

  const resourcePath = useCallback(() => {
    return `${(taskableType || '').toLowerCase()}s`;
  }, [taskableType]);

  if (!task) {
    return null;
  }

  return (
    <td>
      <Link to={`/${resourcePath()}/${task.taskableId}`}>{task.associatedTo}</Link>
    </td>
  );
}
