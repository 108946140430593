import React from 'react';
import { Form, FormGroup, DatePicker, Button } from 'shards-react';

export default class EditFreeTrialPeriodForm extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const freeUntil = new Date(props.freeUntil);
    this.state = {
      data: { freeUntil: freeUntil > today ? freeUntil : today }
    };
  }

  render() {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.props
            .onSubmit({
              variables: { id: this.props.id, date: this.state.data.freeUntil }
            })
            .then(() => {
              this.props.onSuccess();
            })
            .catch((error) => {
              this.props.onError(error);
            });
        }}
      >
        <FormGroup>
          <label htmlFor="freeUntil">Extender período de prueba</label>
          <br />
          <DatePicker
            id="freeUntil"
            isClearable
            dateFormat="yyyy/MM/dd"
            selected={this.state.data.freeUntil}
            dropdownMode="select"
            onChange={(date) => {
              this.setState({ data: { freeUntil: date } });
            }}
          />
        </FormGroup>
        <Button type="submit">Extender</Button>
      </Form>
    );
  }
}
