import React from 'react';
import { Form, FormGroup, Button, FormInput, FormCheckbox } from 'shards-react';

import { AuthContext } from '../../contexts/AuthContext.js';

export default class AccountForm extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const { id, name, enterprise, manualScraper } = props.account || {};

    this.state = {
      id,
      name,
      manualScraper,
      enterprise,
      submitting: false
    };
  }

  render() {
    const { id, name, enterprise, manualScraper } = this.state;

    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.setState({ submitting: true });
          this.props
            .mutate({
              variables: {
                id,
                newName: name,
                manualScraper,
                enterprise
              }
            })
            .then((response) => this.props.onSuccess(response.data.account))
            .catch((error) => this.props.onError(error))
            .finally(() => this.setState({ submitting: false }));
        }}
      >
        <FormGroup>
          <label htmlFor="newAccountName">Nombre</label>
          <FormInput
            value={this.state.name}
            id="newAccountName"
            onChange={(event) => {
              this.setState({ name: event.target.value });
            }}
            required
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="manualScraper">Scraper Manual</label>
          <FormCheckbox
            value={manualScraper}
            id="manualScraper"
            checked={manualScraper}
            onChange={() => {
              this.setState({ manualScraper: !manualScraper });
            }}
            toggle
          >
            {manualScraper
              ? 'Esta activado el scraper manual'
              : 'NO esta activado el scraper manual'}
          </FormCheckbox>

          <label htmlFor="enterprise">Enterprise</label>
          <FormCheckbox
            checked={enterprise}
            id="enterprise"
            onChange={() => {
              this.setState({ enterprise: !enterprise });
            }}
            toggle
          >
            {enterprise ? 'La cuenta es enterprise' : 'La cuenta NO es enterprise'}
          </FormCheckbox>
        </FormGroup>

        <Button disabled={this.state.submitting} type="submit">
          Actualizar Cuenta
        </Button>
      </Form>
    );
  }
}
