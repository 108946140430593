import TextField from '../../components/fields/show/TextField';
import ShowDateField from '../../components/fields/show/DateField';

const userFields = {
  show: {
    id: { label: 'ID', content: TextField },
    firstName: { label: 'Nombre', content: TextField },
    lastName: { label: 'Apellido', content: TextField },
    email: { label: 'Email', content: TextField },
    phoneNumber: { label: 'Telefono', content: TextField },
    createdAt: { label: 'Fecha de creación', content: ShowDateField }
  }
};

export default userFields;
