import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';
import SubscriptionFragments from '../queries/fragments/SubscriptionFragments';

export default function MutationCancelLicenses({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation CancelLicenses($id: String!, $motive: String) {
          subscription: cancelLicenses(id: $id, motive: $motive) {
            ...SubscriptionFields
          }
        }
        ${SubscriptionFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
