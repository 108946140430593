import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QueryCountries({ children }) {
  return (
    <SmartQuery
      query={gql`
        query Countries {
          countries {
            label
            value
          }
        }
      `}
      variables={{}}
    >
      {(data) => children(data.countries || [])}
    </SmartQuery>
  );
}
