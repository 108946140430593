import gql from 'graphql-tag';

const SubscriptionFragments = {};

SubscriptionFragments.fields = gql`
  fragment SubscriptionFields on SubscriptionResponse {
    id
    state
    country
    terminalsCount
    updateBlocked
    freeUntil
    initialBilling
    bonusFree
    grossIncomeRetention
    netAmount
    chargeableAmount
    netAmountAfterDiscounts
    integrationByApi
    fixedPrice
    salesOwner {
      id
      email
    }
    createdAt
    product {
      id
      name
    }
    account {
      id
      name
    }
    company {
      id
      legalName
      country
      taxId
      metadata
      address
      phoneNumber
    }
    partner {
      id
      name
    }
    contactCenter {
      id
      name
    }
    events {
      id
      name
      eventTime
      metadata
    }
    huntingEvents {
      id
      name
      eventTime
    }
    audit {
      before
      after
      user {
        email
      }
      date
    }
    discounts {
      id
      name
      percentage
      recurringCycles
      recurringCyclesLeft
      amount
      createdAt
    }
    discountsHistory {
      id
      name
      percentage
      recurringCycles
      recurringCyclesLeft
      amount
      createdAt
    }
  }
`;

export default SubscriptionFragments;
