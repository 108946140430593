import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QueryInvitationStates({ children }) {
  return (
    <SmartQuery
      query={gql`
        query InvitationStates {
          states: invitationStates {
            id
            label
          }
        }
      `}
      variables={{}}
    >
      {(data) => children(data.states)}
    </SmartQuery>
  );
}
