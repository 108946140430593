import React from 'react';
import { Container, Row, Card, Col, CardBody, Alert, Button } from 'shards-react';
import { Link } from 'react-router-dom';

import PageTitle from '../../layouts/PageTitle';
import ServerError from '../../components/ServerError';
import AccountForm from '../../components/edit/AccountForm';
import MutationUpdateAccount from '../../mutations/MutationUpdateAccount';
import MutationCreateSubscription from '../../mutations/MutationCreateSubscription';
import CreateSubscription from './CreateSubscription';
import QueryPlatformProducts from '../../queries/QueryPlatformProducts';

export default class AccountEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      change_success: false
    };
  }

  successChange = () => {
    this.setState({ change_success: true });
  };

  onError = (error) => {
    this.setState({ error, change_success: false });
  };

  render() {
    return (
      <Container fluid>
        <Row className="page-header py-4 xalign-items-center" noGutters>
          <PageTitle className="text-sm-left" subtitle="" title={this.props.title} />
          <Col xs="auto">
            <Link to={`/accounts/${this.props.match.params.id}`}>
              <Button theme="primary" size="sm">
                Ver
              </Button>
            </Link>
          </Col>
        </Row>
        {this.state.change_success && <Alert theme="success">La cuenta ha sido actualizada.</Alert>}

        <ServerError error={this.state.error} />
        <this.props.queryData id={this.props.match.params.id}>
          {(account, refetch) => (
            <Row>
              <Col lg="6">
                <Card style={{ minHeight: '350px' }}>
                  <CardBody>
                    <MutationUpdateAccount>
                      {(mutate) => (
                        <AccountForm
                          mutate={mutate}
                          onSuccess={this.successChange}
                          onError={this.onError}
                          account={account}
                          refetch={refetch}
                        />
                      )}
                    </MutationUpdateAccount>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card style={{ minHeight: '350px' }}>
                  <CardBody>
                    <MutationCreateSubscription>
                      {(mutate) => (
                        <QueryPlatformProducts>
                          {(products) => (
                            <CreateSubscription
                              mutate={mutate}
                              refetch={refetch}
                              subscriptions={account.subscriptions}
                              accountId={account.id}
                              onSuccess={this.successChange}
                              onError={this.onError}
                              products={products}
                            />
                          )}
                        </QueryPlatformProducts>
                      )}
                    </MutationCreateSubscription>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </this.props.queryData>
      </Container>
    );
  }
}
