import React from 'react';
import { Form, FormGroup, FormInput, Button } from 'shards-react';

export default class EditLicensesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { update_licenses: props.quantity }
    };
  }

  render() {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.props
            .mutate({
              variables: { id: this.props.id, amount: this.state.data.update_licenses }
            })
            .then(() => {
              this.props.onSuccess();
            })
            .catch((error) => {
              this.props.onError(error);
            });
        }}
      >
        <FormGroup>
          <label htmlFor="update_licenses">
            {this.props.fixedPrice ? 'Precio Fijo' : 'Licencias'}
          </label>
          <FormInput
            defaultValue={this.props.quantity}
            id="update_licenses"
            onChange={(event) => {
              this.setState({ data: { update_licenses: parseInt(event.target.value) } });
            }}
            type="number"
            min="1"
          />
        </FormGroup>
        <Button type="submit">
          {this.props.fixedPrice ? 'Actualizar Precio' : 'Actualizar licencias'}
        </Button>
      </Form>
    );
  }
}
