const navItems = [
  {
    value: 'Invitar usuario',
    to: '/users/invites/new',
    htmlBefore: '<i class="material-icons">add</i>',
    htmlAfter: '',
    permission: 'inv:create'
  },
  {
    value: 'Invitaciones',
    to: '/invitations',
    htmlBefore: '<i class="material-icons">person</i>',
    htmlAfter: '',
    permission: 'inv:list'
  },
  {
    value: 'Cuentas',
    to: '/accounts',
    htmlBefore: '<i class="material-icons">group</i>',
    htmlAfter: '',
    permission: 'acc:list',
    tabs: [
      {
        value: 'Migraciones',
        to: '/accounts/migrations'
      }
    ]
  },
  {
    value: 'Suscripciones',
    to: '/subscriptions',
    htmlBefore: '<i class="material-icons">widgets</i>',
    htmlAfter: '',
    permission: 'sub:list'
  },
  {
    value: 'Tareas',
    to: '/tasks',
    htmlBefore: '<i class="material-icons">list</i>',
    htmlAfter: '',
    permission: 'tsk:list'
  }
];

export default navItems;
