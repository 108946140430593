import gql from 'graphql-tag';

const InvitationFragments = {};

InvitationFragments.fields = gql`
  fragment InvitationFields on Invitation {
    id
    inviter {
      id
      email
      firstName
      lastName
    }
    partner {
      id
      name
    }
    state {
      id
      label
    }
    inviteeEmail
    acceptedAt
    createdAt
    emailSentAt
    acceptedByOther
  }
`;

export default InvitationFragments;
