import styled from 'styled-components';

export default styled.table`
  tr td {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
    font-weight: normal;
  }

  td.actions {
    text-align: right;
    padding-right: 2rem;
  }
`;
