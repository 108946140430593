import React from 'react';

import MutationRemoveDiscount from '../../mutations/MutationRemoveDiscount';

export default function RemoveDiscount({ subscriptionId, discountId, onSuccess, onError }) {
  const remove = (mutate) => {
    mutate({ variables: { subscriptionId, discountId: parseInt(discountId) } })
      .then(onSuccess)
      .catch(onError);
  };

  return (
    <MutationRemoveDiscount>
      {(mutate) => (
        <i
          onClick={() => remove(mutate)}
          title="Click para remover descuento"
          className="material-icons ml-2"
        >
          close
        </i>
      )}
    </MutationRemoveDiscount>
  );
}
