import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery';
import AccountFragments from './fragments/AccountFragments';
import Comment from './fragments/CommentFragments.js';

export const query = gql`
  query Account($id: String!) {
    account: account(id: $id) {
      ...AccountFields
      comments {
        ...CommentFields
      }
    }
  }
  ${AccountFragments.fields}
  ${Comment.fields}
`;

export default function QueryAccount({ id, children }) {
  return (
    <SmartQuery query={query} variables={{ id: id }}>
      {(data, refetch) => children(data.account, refetch)}
    </SmartQuery>
  );
}
