import React from 'react';

export default function DateField({ value }) {
  return (
    <td>
      {value &&
        new Date(value).toLocaleString('es', { year: 'numeric', month: 'numeric', day: 'numeric' })}
    </td>
  );
}
