import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QueryDiscounts({ children, subscriptionId }) {
  return (
    <SmartQuery
      query={gql`
        query Discounts($subscriptionId: String!) {
          discounts(subscriptionId: $subscriptionId) {
            id
            label
          }
        }
      `}
      variables={{ subscriptionId }}
    >
      {(data) => children(data.discounts)}
    </SmartQuery>
  );
}
