import gql from 'graphql-tag';

const UserFragments = {};

UserFragments.fields = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    email
    owner
    phoneNumber
    createdAt
  }
`;

export default UserFragments;
