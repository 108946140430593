import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';

export default function MutationCreateAccountMigration({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation MutationCreateAccountMigration($fromId: String!, $toId: String!) {
          accountMigration: createAccountMigration(fromId: $fromId, toId: $toId) {
            id
          }
        }
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
