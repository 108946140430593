import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';
import AccountFragments from '../queries/fragments/AccountFragments';

export default function MutationUpdateAccount({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation UpdateAccount(
          $id: String!
          $newName: String
          $enterprise: Boolean
          $manualScraper: Boolean
        ) {
          account: updateAccount(
            id: $id
            name: $newName
            enterprise: $enterprise
            manualScraper: $manualScraper
          ) {
            ...AccountFields
          }
        }
        ${AccountFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
