import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from './AuthContext';
import Rollbar from 'rollbar';

export const ErrorTrackingContext = React.createContext(null);

export function ErrorTrackingProvider({ children, ...settings }) {
  const authState = useAuth();
  const [tracker, setTracker] = useState(null);
  const [trackerConfigured, setTrackerConfigured] = useState(false);

  const trackingEnabled = settings.enabled === true && process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (!trackingEnabled || tracker !== null) return;
    setTracker(
      new Rollbar({
        accessToken: settings.rollbarToken,
        environment: 'production',
        captureUncaught: true,
        captureUnhandledRejections: true
      })
    );
  }, [settings, tracker, trackingEnabled]);

  useEffect(() => {
    if (authState.authenticated === false) {
      setTrackerConfigured(true);
      return;
    }
    if (authState.authenticated === null || tracker === null) {
      // Wait of auth check
      return;
    }
    const user = authState.user;
    tracker.configure({
      payload: {
        person: {
          id: user.email,
          username: user.email,
          email: user.email
        }
      }
    });
    setTrackerConfigured(true);
  }, [authState, tracker, trackingEnabled]);

  if (!trackingEnabled) {
    return <ErrorTrackingContext.Provider value={null}>{children}</ErrorTrackingContext.Provider>;
  }

  // We block until we know if there is a user to identify or not
  // This avoids a race condition that causes anonymous Errors to be sent
  if (authState.authenticated === null || tracker === null || !trackerConfigured) return null;

  return <ErrorTrackingContext.Provider value={tracker}>{children}</ErrorTrackingContext.Provider>;
}

export function useErrorTracker() {
  return useContext(ErrorTrackingContext);
}
