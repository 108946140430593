import { Role } from '../roles';

const subscriptionNotCanceled = ({ subscription }) => subscription.state !== 'canceled';
const subscriptionCancelableState = ({ subscription }) => {
  return ['awaiting_setup', 'free_trial', 'awaiting_payment_data'].includes(subscription.state);
};
const assignableTask = () => ({ assignedToId, taskableType }) => {
  return assignedToId && (taskableType === 'Invitation' || taskableType === 'Subscription');
};

// Permission format
// NAMESPACE-ACTION-[PERMISSION]

// -- NAMESPACE --
// sub -> subscriptions
// inv -> invitations
// tsk -> tasks
// acc -> accounts

// -- ACTION --
// list | create | edit

// -- PERMISSION --
// string

const basePermissions = {
  [Role.ContactCenterAdmin]: {
    'inv:create': true,
    'inv:create:assign-salesman': true,
    'inv:create:assign-partner': true,
    'inv:list': true,

    'sub:edit:integration-api': true,
    'sub:edit:assignee': true,
    'sub:edit:cancel': subscriptionNotCanceled,
    'sub:list': true,
    'sub:list:show-sales-owner': true,
    'sub:search:sales-owner': true,
    'sub:edit:hunting-events': true,
    'sub:show:hunting-events': true,

    'tsk:edit:assign': assignableTask,
    'tsk:edit:show-assigned': true,
    'tsk:list': true
  },
  [Role.PartnerExecutive]: {
    'sub:edit:cancel': subscriptionNotCanceled,

    'tsk:edit:assign': assignableTask,
    'tsk:edit:show-assigned': true
  },
  [Role.PartnerSalesman]: {
    'inv:create': true,
    'inv:create:assign-salesman': true,
    'inv:create:assign-partner': true,
    'inv:list': true,

    'sub:edit:cancel': subscriptionCancelableState,
    'sub:list': true,

    'tsk:list': true
  },
  [Role.CommercialAdmin]: {
    'acc:edit': true,
    'acc:list': true,
    'acc:search:sales-owner': true,
    'acc:search:partner': true,
    'acc:show:invoice-amount': true,
    'acc:show:invoice-tax-amount': true,
    'acc:show:invoice-expiration-date': true,
    'acc:show:invoice-external-link': true,

    'inv:create': true,
    'inv:create:assign-salesman': true,
    'inv:create:assign-partner': true,
    'inv:list': true,
    'inv:search:partner': true,
    'inv:search:sales-owner': true,

    'sub:edit:integration-api': true,
    'sub:edit:cancel': subscriptionNotCanceled,
    'sub:edit:assignee': true,
    'sub:edit:partner': true,
    'sub:edit:charge-one-shot': true,
    'sub:edit:hunting-events': true,
    'sub:list': true,
    'sub:list:show-partner': true,
    'sub:list:show-sales-owner': true,
    'sub:search:partner': true,
    'sub:search:contact-center': true,
    'sub:show:hunting-events': true,
    'sub:show:amounts': true,

    'tsk:edit:assign': assignableTask,
    'tsk:edit:show-assigned': true,
    'tsk:list': true
  },
  [Role.SupportAdmin]: {
    'acc:list': true,

    'sub:edit:cancel': subscriptionNotCanceled,
    'sub:list': true,
    'sub:list:show-partner': true,
    'sub:search:partner': true,
    'sub:search:contact-center': true,
    'sub:search:sales-owner': true,

    'tsk:edit:assign': assignableTask,
    'tsk:edit:show-assigned': true,
    'tsk:list': true
  },
  [Role.PartnerAdmin]: {
    'acc:list': true,

    'inv:create': true,
    'inv:list': true,

    'sub:edit:cancel': subscriptionNotCanceled,
    'sub:list': true,

    'tsk:edit:assign': assignableTask,
    'tsk:edit:show-assigned': true,

    'sub:edit:hunting-events': true,
    'sub:show:hunting-events': true
  }
};

export const permissions = {
  ...basePermissions,
  [Role.FinanceAdmin]: {
    ...basePermissions[Role.CommercialAdmin],
    'sub:edit:bonus-free': true,
    'sub:edit:gross-income-retention': true,
    'sub:edit:hunting-events': false,
    'sub:show:hunting-events': false
  }
};
