import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QueryPartners({ children }) {
  return (
    <SmartQuery
      query={gql`
        query Partners {
          partners {
            id
            name
          }
        }
      `}
      variables={{}}
    >
      {(data) => children(data.partners)}
    </SmartQuery>
  );
}
