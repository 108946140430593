import React from 'react';
import { Container, Row, Card, Col, CardBody, Alert, Button } from 'shards-react';
import { Link } from 'react-router-dom';

import PageTitle from '../../layouts/PageTitle';
import MutationChangeAssignee from '../../mutations/MutationChangeAssignee';
import MutationExtendFreeTrialPeriod from '../../mutations/MutationExtendFreeTrialPeriod';
import MutationUpdateLicenses from '../../mutations/MutationUpdateLicenses';
import MutationCancelLicenses from '../../mutations/MutationCancelLicenses';
import EditFreeTrialPeriodForm from './EditFreeTrialPeriodForm';
import EditLicensesForm from './EditLicensesForm';
import CancelLicensesForm from './CancelLicensesForm';
import subscriptionStateMap from '../../data/fields/mappings/subscriptionStateMap';
import ServerError from '../../components/ServerError';
import ChangeAssigneeSelect from '../../components/ChangeAssigneeSelect';
import ChangeSubscriptionPartner from './ChangeSubscriptionPartner';
import ChargeOneShot from './ChargeOneShot';
import MutationChangeSubscriptionPartner from '../../mutations/MutationChangeSubscriptionPartner';
import MutationChangeSubscriptionContactCenter from '../../mutations/MutationChangeSubscriptionContactCenter';
import MutationChargeOneShot from '../../mutations/MutationChargeOneShot';
import MutationReactivateSubscription from '../../mutations/MutationReactivateSubscription';
import ReactivateSubscription from './ReactivateSubscription';
import EditDiscountForm from './EditDiscountForm';
import MutationApplyDiscount from '../../mutations/MutationApplyDiscount';
import MutationUpdateBonusFree from '../../mutations/MutationUpdateBonusFree';
import ChangeSubscriptionContactCenter from './ChangeSubscriptionContactCenter';
import { Can } from '../../permissions/helpers';
import SubscriptionInfo from './SubscriptionInfo';
import UpdateBonusFree from './UpdateBonusFree';
import UpdateGrossIncomeRetention from './UpdateGrossIncomeRetention';
import MutationUpdateGrossIncomeRetention from '../../mutations/MutationUpdateGrossIncomeRetention';
import MutationEmitHuntingEvent from '../../mutations/MutationEmitHuntingEvent';
import EmitHuntingEvent from './EmitHuntingEvent';
import MutationIntegrationByApi from '../../mutations/MutationIntegrationByApi';
import IntegrationByApi from './IntegrationByApi';
import MutationActivateSubscription from '../../mutations/MutationActivateSubscription';
import ActivateSubscription from './ActivateSubscription';

const avaiableSubscriptionStatus = [
  'awaiting_setup',
  'sandbox',
  'free_trial',
  'awaiting_payment_data'
];

export default class SubscriptionEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      change_success: false
    };
  }

  successChange = () => {
    this.setState({ change_success: true });
  };

  onError = (error) => {
    this.setState({ error, change_success: false });
  };

  render() {
    return (
      <Container className="mb-3" fluid>
        <Row className="page-header py-4 xalign-items-center" noGutters>
          <PageTitle className="text-sm-left" subtitle="" title={this.props.title} />
          <Col xs="auto">
            <Link to={`/subscriptions/${this.props.match.params.id}`}>
              <Button theme="primary" size="sm">
                Ver
              </Button>
            </Link>
          </Col>
        </Row>
        <Card>
          <CardBody>
            {this.state.change_success && (
              <Alert theme="success">Cambio efectuado correctamente</Alert>
            )}

            <ServerError error={this.state.error} />

            <this.props.queryData id={this.props.match.params.id}>
              {(subscription) => (
                <React.Fragment key={subscription.id}>
                  <SubscriptionInfo subscription={subscription} />

                  <Row className="my-2">
                    <Col lg="3" sm="6" xs="12">
                      Estado
                    </Col>
                    <Col lg="9" sm="6" xs="12">
                      <b>{subscriptionStateMap[subscription.state] || subscription.state}</b>
                      {subscription.state === 'canceled' && (
                        <MutationReactivateSubscription>
                          {(mutate) => (
                            <ReactivateSubscription
                              id={subscription.id}
                              mutate={mutate}
                              onSuccess={this.successChange}
                              onError={this.onError}
                              subscription_state={subscription.state}
                            />
                          )}
                        </MutationReactivateSubscription>
                      )}
                      {subscription.product.name === 'pay' &&
                        avaiableSubscriptionStatus.includes(subscription.state) && (
                          <MutationActivateSubscription>
                            {(mutate) => (
                              <ActivateSubscription
                                id={subscription.id}
                                mutate={mutate}
                                subscription={subscription}
                                onSuccess={this.successChange}
                                onError={this.onError}
                              />
                            )}
                          </MutationActivateSubscription>
                        )}
                    </Col>
                  </Row>
                  <Can action="sub:edit:assignee">
                    <Row>
                      <Col lg="3" sm="6" xs="12">
                        Vendedor asignado
                      </Col>
                      <Col lg="9" sm="6" xs="12">
                        <MutationChangeAssignee>
                          {(mutate) => (
                            <ChangeAssigneeSelect
                              id={subscription.id}
                              contactCenter={subscription.contactCenter}
                              salesOwner={subscription.salesOwner}
                              onSubmit={mutate}
                              onSuccess={this.successChange}
                              onError={this.onError}
                            ></ChangeAssigneeSelect>
                          )}
                        </MutationChangeAssignee>
                      </Col>
                    </Row>
                  </Can>

                  {!subscription.updateBlocked && (
                    <React.Fragment>
                      <Row>
                        <Col lg="6">
                          <hr />
                          <MutationExtendFreeTrialPeriod>
                            {(mutate) => (
                              <EditFreeTrialPeriodForm
                                id={subscription.id}
                                freeUntil={subscription.freeUntil}
                                onSubmit={mutate}
                                onSuccess={this.successChange}
                                onError={this.onError}
                              />
                            )}
                          </MutationExtendFreeTrialPeriod>
                        </Col>

                        <Col lg="6">
                          <hr />
                          <MutationApplyDiscount>
                            {(mutate) => (
                              <EditDiscountForm
                                subscriptionId={subscription.id}
                                onSubmit={mutate}
                                currentDiscounts={subscription.discounts}
                                onSuccess={this.successChange}
                                onError={this.onError}
                              />
                            )}
                          </MutationApplyDiscount>
                        </Col>

                        <Col lg="6">
                          <hr />
                          <MutationUpdateLicenses>
                            {(mutate) => (
                              <EditLicensesForm
                                id={subscription.id}
                                mutate={mutate}
                                onSuccess={this.successChange}
                                onError={this.onError}
                                quantity={subscription.terminalsCount}
                                fixedPrice={subscription.fixedPrice}
                              />
                            )}
                          </MutationUpdateLicenses>
                        </Col>

                        <Can action="sub:edit:cancel" data={{ subscription: subscription }}>
                          <Col lg="6">
                            <hr />
                            <MutationCancelLicenses>
                              {(mutate) => (
                                <CancelLicensesForm
                                  id={subscription.id}
                                  mutate={mutate}
                                  onSuccess={this.successChange}
                                  onError={this.onError}
                                  subscription_state={subscription.state}
                                />
                              )}
                            </MutationCancelLicenses>
                          </Col>
                        </Can>

                        <Can action="sub:edit:partner">
                          <Col lg="6">
                            <hr />
                            <label htmlFor="update-Partner">Partner</label>
                            <p>
                              <b>{subscription.partner && subscription.partner.name}</b>
                            </p>
                            <MutationChangeSubscriptionPartner>
                              {(mutate) => (
                                <ChangeSubscriptionPartner
                                  mutate={mutate}
                                  id={subscription.id}
                                  onSuccess={this.successChange}
                                  onError={this.onError}
                                />
                              )}
                            </MutationChangeSubscriptionPartner>
                          </Col>

                          <Col lg="6">
                            <hr />

                            <label htmlFor="update-contactCenter">Contact Center</label>
                            <p>
                              <b>{subscription.contactCenter && subscription.contactCenter.name}</b>
                            </p>
                            <MutationChangeSubscriptionContactCenter>
                              {(mutate) => (
                                <ChangeSubscriptionContactCenter
                                  mutate={mutate}
                                  id={subscription.id}
                                  onSuccess={this.successChange}
                                  onError={this.onError}
                                />
                              )}
                            </MutationChangeSubscriptionContactCenter>
                          </Col>
                        </Can>

                        <Can action="sub:edit:charge-one-shot">
                          <Col lg="6">
                            <hr />
                            <MutationChargeOneShot>
                              {(mutate) => (
                                <ChargeOneShot
                                  mutate={mutate}
                                  id={subscription.id}
                                  amount={0.0}
                                  taxConsumption={0.0}
                                  onSuccess={this.successChange}
                                  onError={this.onError}
                                />
                              )}
                            </MutationChargeOneShot>
                          </Col>
                        </Can>

                        <Can action="sub:edit:gross-income-retention">
                          <Col lg="6">
                            <hr />
                            <MutationUpdateGrossIncomeRetention>
                              {(mutate) => (
                                <UpdateGrossIncomeRetention
                                  mutate={mutate}
                                  subscription={subscription}
                                  onSuccess={this.successChange}
                                  onError={this.onError}
                                />
                              )}
                            </MutationUpdateGrossIncomeRetention>
                          </Col>
                        </Can>

                        <Can action="sub:edit:bonus-free">
                          <Col lg="6">
                            <hr />
                            <MutationUpdateBonusFree>
                              {(mutate) => (
                                <UpdateBonusFree
                                  mutate={mutate}
                                  subscription={subscription}
                                  onSuccess={this.successChange}
                                  onError={this.onError}
                                />
                              )}
                            </MutationUpdateBonusFree>
                          </Col>
                        </Can>

                        <Can action="sub:edit:hunting-events">
                          <Col lg="6">
                            <hr />
                            <MutationEmitHuntingEvent>
                              {(mutate) => (
                                <EmitHuntingEvent
                                  mutate={mutate}
                                  subscription={subscription}
                                  onSuccess={this.successChange}
                                  onError={this.onError}
                                />
                              )}
                            </MutationEmitHuntingEvent>
                          </Col>
                        </Can>

                        <Can action="sub:edit:integration-api">
                          <Col lg="6">
                            <hr />
                            <MutationIntegrationByApi>
                              {(mutate) => (
                                <IntegrationByApi
                                  id={subscription.id}
                                  mutate={mutate}
                                  subscription={subscription}
                                  onSuccess={this.successChange}
                                  onError={this.onError}
                                />
                              )}
                            </MutationIntegrationByApi>
                          </Col>
                        </Can>
                      </Row>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </this.props.queryData>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
