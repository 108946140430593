import React from 'react';
import { ApolloConsumer } from '@apollo/client';
import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Alert,
  Button,
  Tooltip,
  CardFooter
} from 'shards-react';
import AsyncSelect from 'react-select/async';
import Debounce from 'es6-promise-debounce';

import PageTitle from '../../layouts/PageTitle';
import ServerError from '../../components/ServerError';
import { query as getAccountsQuery } from '../../queries/QueryAccounts';
import MutationCreateAccountMigration from '../../mutations/MutationCreateAccountMigration';

export default class AccountMigrationCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      change_success: false,
      from: null,
      to: null
    };
  }

  onSuccess = (succesLink) => {
    this.setState({ change_success: true, succesLink });
  };

  onError = (error) => {
    this.setState({ error, change_success: false });
  };

  onSetTo = (option) => {
    if (this.state.from && option.value === this.state.from.id)
      return this.setState({ toError: true });
    this.setState({ to: { id: option.value, name: option.label } });
  };

  onSetFrom = (option) => {
    if (this.state.to && option.value === this.state.to.id)
      return this.setState({ fromError: true });
    this.setState({ from: { id: option.value, name: option.label } });
  };

  generateSuccesLink = (migrationId) => `/accounts/migrations/${migrationId}`;

  onMutation = (mutate) =>
    mutate({
      variables: { fromId: this.state.from.id, toId: this.state.to.id }
    })
      .then((result) => {
        console.log(result);
        this.onSuccess(this.generateSuccesLink(result.data.accountMigration.id));
      })
      .catch((error) => {
        console.error(error);
        this.onError(error);
      });

  getAccounts = async (gqlClient, filter) => {
    const { loading, error, data } = await gqlClient.query({
      query: getAccountsQuery,
      variables: { filter }
    });
    if (loading) return [];
    if (error) this.onError(error);

    return data.accounts;
  };

  generatedebouncedFetchAccounts = (gqlClient) =>
    Debounce(async (accountNameOrId) => {
      //const accountsById = await this.getAccounts(gqlClient, { id: accountNameOrId });
      const accountsByName = await this.getAccounts(gqlClient, { name: accountNameOrId });
      const result = accountsByName.map((account) => {
        return {
          label: `${account.name} - ${account.id}`,
          value: account.id
        };
      });
      return result;
    }, 500);

  render() {
    return (
      <Container fluid>
        <Row className="page-header py-4 xalign-items-center" noGutters>
          <PageTitle className="text-sm-left" subtitle="" title={this.props.title} />
        </Row>
        {this.state.change_success && (
          <Alert theme="success">
            La cuenta ha sido actualizada.{' '}
            <a className="alert-link" href={this.state.succesLink}>
              Ver Migracion
            </a>
          </Alert>
        )}
        <ServerError error={this.state.error} />
        <Row>
          <Col lg="12">
            <MutationCreateAccountMigration>
              {(mutate) => (
                <Card style={{ minHeight: '420px' }}>
                  <CardBody>
                    <ApolloConsumer>
                      {(gqlClient) => (
                        <Row>
                          <Col>
                            <label htmlFor="fromId">Cuenta Origen</label>
                            <AsyncSelect
                              cacheOptions={true}
                              id="fromId"
                              loadOptions={this.generatedebouncedFetchAccounts(gqlClient)}
                              name="fromId"
                              onChange={this.onSetFrom}
                              placeholder="Busca la Cuenta Origen por nombre"
                              value={
                                this.state.from
                                  ? { value: this.state.from.id, label: this.state.from.name }
                                  : ''
                              }
                            />
                            <Tooltip
                              open={this.state.fromError}
                              target="#fromId"
                              toggle={() => this.setState({ fromError: false })}
                            >
                              La Cuenta Origen no puede ser igual a la Destino
                            </Tooltip>
                          </Col>
                          <Col>
                            <label htmlFor="toId">Cuenta Destino</label>
                            <AsyncSelect
                              cacheOptions={true}
                              id="toId"
                              loadOptions={this.generatedebouncedFetchAccounts(gqlClient)}
                              name="toId"
                              onChange={this.onSetTo}
                              placeholder="Busca la Cuenta Destino por nombre"
                              value={
                                this.state.to
                                  ? { value: this.state.to.id, label: this.state.to.name }
                                  : ''
                              }
                            />
                            <Tooltip
                              open={this.state.toError}
                              target="#toId"
                              toggle={() => this.setState({ toError: false })}
                              theme="danger"
                            >
                              La Cuenta Destino no puede ser igual a la Origen
                            </Tooltip>
                          </Col>
                        </Row>
                      )}
                    </ApolloConsumer>
                  </CardBody>
                  <CardFooter>
                    <Button
                      onClick={() => this.onMutation(mutate)}
                      disabled={!this.state.to || !this.state.from}
                    >
                      Migrar Cuenta
                    </Button>
                  </CardFooter>
                </Card>
              )}
            </MutationCreateAccountMigration>
          </Col>
        </Row>
      </Container>
    );
  }
}
