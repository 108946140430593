import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QueryAppUsers({ contactCenterId, children }) {
  return (
    <SmartQuery
      query={gql`
        query AppUsers($contactCenterId: String) {
          users: appUsers(contactCenterId: $contactCenterId) {
            id
            email
            firstName
            lastName
          }
        }
      `}
      variables={{ contactCenterId }}
    >
      {(data) => children(data.users)}
    </SmartQuery>
  );
}
