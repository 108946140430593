import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';
import TaskFragments from './fragments/TaskFragments.js';
import InvitationFragments from './fragments/InvitationFragments.js';

export default function QueryInvitation({ id, children }) {
  return (
    <SmartQuery
      query={gql`
        query Invitation($id: String!) {
          invitation(id: $id) {
            ...InvitationFields
            tasks {
              ...TaskSublistFields
            }
          }
        }
        ${TaskFragments.sublistFields}
        ${InvitationFragments.fields}
      `}
      variables={{ id: id }}
    >
      {(data) => children(data.invitation)}
    </SmartQuery>
  );
}
