import React from 'react';

export default function UserNameField({ value }) {
  if (!value) {
    return <td></td>;
  }

  if (value.firstName && value.lastName) {
    return (
      <td>
        {value.firstName} {value.lastName}
      </td>
    );
  }

  return <td>{value.email}</td>;
}
