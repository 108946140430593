import gql from 'graphql-tag';

const ProductFragments = {};

ProductFragments.fields = gql`
  fragment ProductFields on ProductPlatform {
    id
    name
    displayName
    logo
    uri
    acquireUri
    countries
    isFree
  }
`;

export default ProductFragments;
