import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';

import CommentFragments from '../queries/fragments/CommentFragments';

export default function MutationAddComment({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation MutationAddComment($comment: CommentAttrs!) {
          comment: createComment(comment: $comment) {
            ...CommentFields
          }
        }
        ${CommentFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
