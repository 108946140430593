import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';

export default function MutationUnqualifyInvitation({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation UnqualifyInvitation($id: String!, $reason: String!) {
          success: unqualifyInvitation(id: $id, reason: $reason)
        }
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
