import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'shards-react';

export default function ActivateSubscription(props) {
  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(false);

  const toggle = (event) => {
    event.preventDefault();
    setOpen((currentValue) => !currentValue);
  };

  const activate = (event) => {
    event.preventDefault();
    props.mutate({ variables: { id: props.id } }).then(() => {
      setSent(true);
    });
  };

  return (
    <React.Fragment>
      <Modal open={open} toggle={toggle}>
        <ModalHeader>Activar suscripción</ModalHeader>
        <ModalBody>
          {sent
            ? 'La suscripción ha sido activada'
            : 'Recordá que para que pase a usable deberás ir a ver-como e ir a ajuste de cuentas y cargar la info de facturación'}
        </ModalBody>
        <ModalFooter>
          <Button theme="secondary" onClick={toggle}>
            Cerrar
          </Button>
          {!sent && (
            <Button theme="warning" onClick={activate}>
              Activar
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <a href="/" className="ml-2" onClick={toggle}>
        Activar suscripción
      </a>
    </React.Fragment>
  );
}
