import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col } from 'shards-react';

const TaskButton = ({ type, id, className }) => {
  return (
    <Col xs="auto" className={className}>
      <Link to={`/tasks/${type}/${id}/new`}>
        <Button>Crear Tarea</Button>
      </Link>
    </Col>
  );
};

export default TaskButton;
