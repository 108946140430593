import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';
import TaskFragments from './fragments/TaskFragments.js';

export default function QueryTasks({ filter, page, children }) {
  return (
    <SmartQuery
      query={gql`
        query Tasks($filter: TasksFilter, $page: Int) {
          tasks(filter: $filter, page: $page) {
            ...TaskFields
          }
        }
        ${TaskFragments.fields}
      `}
      variables={{ filter: filter, page: page }}
    >
      {(data) => children(data.tasks)}
    </SmartQuery>
  );
}
