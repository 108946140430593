import React from 'react';
import { Link } from 'react-router-dom';

import ResultTable from './ResultTable';
import TaskResolution from '../fields/list/TaskResolution';

const Tasks = ({ data }) => {
  if (!data || data.length === 0) {
    return <span>No hay tareas para mostrar.</span>;
  }

  return (
    <ResultTable className="table">
      <tr>
        <th>Prioridad</th>
        <th>Creador</th>
        <th>Asignado</th>
        <th>Resuelta?</th>
        <th></th>
      </tr>
      {data.map((task) => (
        <>
          <tr>
            <td>
              <Link to={`tasks/${task.id}`}>{task.priority.name}</Link>
            </td>
            <td>{task.creator.email}</td>
            <td>{task.assignedTo && task.assignedTo.email}</td>
            <TaskResolution value={task.resolutionType} />
            <td className="actions">
              <Link to={`tasks/${task.id}`}>Ver</Link>
            </td>
          </tr>
        </>
      ))}
    </ResultTable>
  );
};

export default Tasks;
