import React, { useState } from 'react';
import { Form, FormGroup, FormSelect, Button } from 'shards-react';
import QueryPartners from '../../queries/QueryPartners';

export default function ChangeSubscriptionPartner(props) {
  const [partnerId, setPartnerId] = useState('');

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        props
          .mutate({ variables: { id: props.id, partnerId } })
          .then(() => props.onSuccess())
          .catch(props.onError);
      }}
    >
      <FormGroup>
        <QueryPartners>
          {(partners) => (
            <FormSelect
              defaultValue={partnerId}
              id="update-partner"
              onChange={(event) => setPartnerId(event.target.value)}
            >
              <option value="">-- Cambiar partner ---</option>
              {partners.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
            </FormSelect>
          )}
        </QueryPartners>
      </FormGroup>

      <Button type="submit" disabled={!partnerId}>
        Actualizar partner
      </Button>
    </Form>
  );
}
