import React from 'react';
import styled from 'styled-components';
import { FormSelect } from 'shards-react';

import QueryAppUsers from '../queries/QueryAppUsers';

const SearchUser = styled(FormSelect)`
  min-width: 140px;
  width: 240px;
  margin-right: 10px;
`;

const ChangeAssigneeSelect = ({ id, salesOwner, contactCenter, onSubmit, onSuccess, onError }) => {
  return contactCenter ? (
    <QueryAppUsers contactCenterId={contactCenter.id}>
      {(users) => (
        <SearchUser
          onChange={(e) => {
            e.preventDefault();
            onSubmit({
              variables: { id: id, salesOwner: e.target.value }
            })
              .then(() => {
                onSuccess();
              })
              .catch((error) => {
                onError(error);
              });
          }}
        >
          <option selected={salesOwner === undefined} value="">
            -- Asignar vendedor --
          </option>
          {users.map((u) => (
            <option key={u.id} selected={salesOwner && u.id === salesOwner.id} value={u.id}>
              {u.email}
            </option>
          ))}
        </SearchUser>
      )}
    </QueryAppUsers>
  ) : (
    'Seleccione un contact center primero'
  );
};

export default ChangeAssigneeSelect;
