import React, { useState, useEffect } from 'react';
import { Form, FormGroup, FormInput, Button, FormSelect } from 'shards-react';

import QueryCustomFields from '../../queries/QueryCustomFields';
import CustomField from '../fields/edit/CustomField';
import { Role } from '../../roles';
import { useRole } from '../../contexts/AuthContext';
import { CountrySelect } from '../FormControls/CountrySelect';
import { PartnerSelect } from '../FormControls/PartnerSelect';
import { PhoneInput } from '../FormControls/PhoneInput';
import { SalesmanSelect } from '../FormControls/SalesmanSelect';
import { Can } from '../../permissions/helpers';

const countryTelCode = (countries, countryValue) => {
  const country = countries.find((c) => c.value === countryValue);
  return country ? country.telCode : '';
};

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

const InviteForm = (props) => {
  const { user } = props;
  const role = useRole();

  const [state, setState] = useState({
    email: '',
    phone: '',
    phoneCountryCode: '',
    firstName: '',
    lastName: '',
    extraFields: {},
    country: props.user.partner.countries[0].value,
    product: props.user.partner.products[0].value,
    leadNode: props.user.partner.nodes[0].value,
    salesOwnerId: props.user.id,
    isSubmitting: false,
    partnerId: props.user.partnerId
  });

  useEffect(() => {
    const partnerId = role === Role.PartnerAdmin ? user.partnerId : user.contactCenterId;

    setState((prevState) => ({ ...prevState, partnerId }));
  }, [role, user]);

  const updateExtraField = (field, value) => {
    const newState = { [field.name]: value };

    setState((prevState) => ({
      ...prevState,
      extraFields: { ...prevState.extraFields, ...newState }
    }));
  };

  const extraFieldValue = (field) => {
    const extraFields = state.extraFields || {};

    return extraFields[field.name];
  };

  const fields = Object.keys(state.extraFields || {}).reduce((result, key) => {
    result.push({ name: key, value: state.extraFields[key] });
    return result;
  }, []);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        const variables = {
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          phone: state.phone,
          country: state.country,
          product: state.product,
          leadNode: state.leadNode,
          salesOwnerId: state.salesOwnerId,
          extraFields: fields,
          partnerId: state.partnerId
        };

        setState((prevState) => ({ ...prevState, isSubmitting: true }));

        props.mutate({ variables }).then(
          (_) => {
            props.onSuccess();
          },
          (error) => {
            props.onError(error);
            setState((prevState) => ({ ...prevState, isSubmitting: false }));
          }
        );
      }}
    >
      <CountrySelect
        required
        countries={user.partner.countries}
        onChange={(country) => {
          const phoneCountryCode = countryTelCode(user.partner.countries, country);
          setState((prevState) => ({ ...prevState, country, phoneCountryCode }));
        }}
      />

      <FormGroup>
        <label htmlFor="product">Producto</label>
        <FormSelect
          id="product"
          onChange={(event) => {
            const product = event.target.value;
            setState((prevState) => ({ ...prevState, product }));
          }}
        >
          {user.partner.products.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </FormSelect>
      </FormGroup>

      <Can action="inv:create:assign-partner">
        <PartnerSelect
          product={state.product}
          defaultValue={state.partnerId}
          country={state.country}
          onChange={(partnerId) => {
            setState((prevState) => ({ ...prevState, partnerId }));
          }}
          required
        />
      </Can>

      <FormGroup>
        <label htmlFor="firstName">Nombre</label>
        <FormInput
          id="firstName"
          onChange={(event) => {
            const firstName = event.target.value;
            setState((prevState) => ({ ...prevState, firstName }));
          }}
          type="text"
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="lastName">Apellido</label>
        <FormInput
          id="lastName"
          onChange={(event) => {
            const lastName = event.target.value;
            setState((prevState) => ({ ...prevState, lastName }));
          }}
          type="text"
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="email">Email del usuario</label>
        <FormInput
          id="email"
          onChange={(event) => {
            const email = event.target.value;
            setState((prevState) => ({ ...prevState, email }));
            if (state.isSubmitting) {
              setState((prevState) => ({ ...prevState, isSubmitting: false }));
            }
          }}
          pattern={emailRegex.source}
          type="email"
          required
        />
      </FormGroup>

      <PhoneInput
        phoneCountryCode={state.phoneCountryCode}
        countries={user.partner.countries}
        onChange={(phone) => {
          setState((prevState) => ({ ...prevState, phone }));
        }}
      />

      <FormGroup>
        <label htmlFor="leadNode">Nodo</label>
        <FormSelect
          id="leadNode"
          onChange={(event) => {
            const leadNode = event.target.value;
            setState((prevState) => ({ ...prevState, leadNode }));
          }}
        >
          {user.partner.nodes.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </FormSelect>
      </FormGroup>

      <Can action="inv:create:assign-salesman">
        <SalesmanSelect
          user={user}
          onChange={(salesOwnerId) => {
            setState((prevState) => ({ ...prevState, salesOwnerId }));
          }}
        />
      </Can>

      <QueryCustomFields partnerId={state.partnerId}>
        {(data) =>
          data.map((field) => (
            <FormGroup key={field.id}>
              <CustomField
                key={field.id}
                field={field}
                value={extraFieldValue(field)}
                onChange={(event) => updateExtraField(field, event.target.value)}
              />
            </FormGroup>
          ))
        }
      </QueryCustomFields>

      <Button type="submit" disabled={state.isSubmitting}>
        Invitar usuario
      </Button>
    </Form>
  );
};

export default InviteForm;
