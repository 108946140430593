import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery';

export default function QueryAccountMigration({ id, children }) {
  return (
    <SmartQuery
      query={gql`
        query AccountMigration($id: String!) {
          accountMigration(id: $id) {
            id
            from {
              id
              name
              taxId
              createdAt
              deletedAt
            }
            to {
              id
              name
              taxId
              createdAt
              deletedAt
            }
            author {
              id
              firstName
              lastName
              email
            }
            createdAt
            events {
              name
              createdAt
              metadata
            }
          }
        }
      `}
      variables={{ id: id }}
    >
      {(data, refetch) => children(data.accountMigration, refetch)}
    </SmartQuery>
  );
}
