import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Container, Card, CardBody, Row } from 'shards-react';
import { isEmpty } from 'lodash';

import PageTitle from '../../layouts/PageTitle';
import QuerySearch from '../../queries/QuerySearch';
import Accounts from './Accounts';
import Invitations from './Invitations';
import Tasks from './Tasks';

const SearchResults = () => {
  let location = useLocation();
  const [term, setTerm] = useState();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);

      setTerm(params.get('term'));
    }
  }, [location]);

  const title = `Resultado de la búsqueda para "${term}"`;

  if (isEmpty(term)) {
    return <h2 className="px-4 my-3">Ingrese un texto para buscar.</h2>;
  }

  return (
    <Container fluid>
      <Row className="page-header py-4" noGutters>
        <PageTitle className="text-sm-left" sm="4" title={title} />
      </Row>

      {term && (
        <QuerySearch q={term}>
          {(data) => (
            <React.Fragment>
              <Card>
                <CardBody>
                  <h5>Cuentas:</h5>
                  <Accounts data={data.accounts} />
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardBody>
                  <h5>Invitaciones:</h5>
                  <Invitations data={data.invitations} />
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardBody>
                  <h5>Tareas:</h5>
                  <Tasks data={data.tasks} />
                </CardBody>
              </Card>
              <br />
            </React.Fragment>
          )}
        </QuerySearch>
      )}
    </Container>
  );
};

export default SearchResults;
