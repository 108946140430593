import React, { useMemo, useState } from 'react';
import { Container, Row, Card, CardBody } from 'shards-react';
import { isEmpty, pickBy } from 'lodash';
import PageTitle from '../layouts/PageTitle';
import ShowBody from './ShowBody';
import TaskButton from './TaskButton';
import EditButton from './EditButton';
import { useAuthorize } from '../permissions/helpers';
import CommentsModals from './CommentsModal';
import CommentsButton from './CommentsButton';
import MutationAddComment from '../mutations/MutationAddComment';

export default function(props) {
  const { fields } = props;
  const isAuthorized = useAuthorize();
  const authorizedFiels = useMemo(() => {
    return pickBy(fields, (field) => isEmpty(field.permission) || isAuthorized(field.permission));
  }, [isAuthorized, fields]);
  const [openCommentBox, setOpenCommentBox] = useState(false);

  return (
    <Container fluid>
      <Row className="page-header py-4 position-relative" noGutters>
        <PageTitle className="text-sm-left" title={props.title} />

        {props.editable && <EditButton id={props.match.params.id} />}

        {props.enableTask && (
          <TaskButton className="ml-3" id={props.match.params.id} type={props.enableTask} />
        )}
      </Row>

      <props.queryData id={props.match.params.id}>
        {(data, refetch) => (
          <Card>
            <CardBody>
              <ShowBody data={data} fields={authorizedFiels} />
            </CardBody>
            {props.commentsEnable && (
              <MutationAddComment>
                {(mutate) => (
                  <CommentsModals
                    openCommentBox={openCommentBox}
                    setOpenCommentBox={setOpenCommentBox}
                    queryData={data}
                    refetch={refetch}
                    mutate={mutate}
                  />
                )}
              </MutationAddComment>
            )}
          </Card>
        )}
      </props.queryData>

      {props.commentsEnable && !openCommentBox && (
        <CommentsButton setOpenCommentBox={setOpenCommentBox} />
      )}
    </Container>
  );
}
