import React, { useState } from 'react';
import { Button, FormGroup, Form, FormSelect } from 'shards-react';
import { huntingEvents } from '../../data/fields/mappings/subscriptionEventsMap';

const EmitHuntingEvent = ({ mutate, subscription, onSuccess, onError }) => {
  const [event, setEvent] = useState('');
  const [emitted, setEmitted] = useState(false);
  const onSelectDiscount = (event) => {
    setEvent(event.target.value);
  };

  return (
    <Form
      onSubmit={(ev) => {
        ev.preventDefault();
        mutate({ variables: { id: subscription.id, event } })
          .then(() => {
            setEmitted(true);
            setEvent('');
            onSuccess();
          })
          .catch((error) => onError(error));
      }}
    >
      <FormGroup>
        <label>Eventos de hunting</label>
        <FormSelect value={event} onChange={onSelectDiscount}>
          <option value="">-- Seleccionar evento ---</option>
          {Object.entries(huntingEvents).map(([name, label]) => (
            <option key={name} value={name}>
              {label}
            </option>
          ))}
        </FormSelect>
        {emitted && <span>Evento emitido correctamente.</span>}
      </FormGroup>
      <Button type="submit">Emitir evento</Button>
    </Form>
  );
};

export default EmitHuntingEvent;
