import React from 'react';
import { Form, FormGroup, FormInput, Button, Col } from 'shards-react';

export default class ChargeOneShot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { amount: props.amount, taxConsumption: props.taxConsumption }
    };
  }

  render() {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.props
            .mutate({
              variables: {
                id: this.props.id,
                amount: parseFloat(this.state.data.amount),
                taxConsumption: parseFloat(this.state.data.taxConsumption)
              }
            })
            .then(() => {
              this.props.onSuccess();
            })
            .catch((error) => {
              this.props.onError(error);
            });
        }}
      >
        <FormGroup row>
          <Col xs="6">
            <label htmlFor="charge_one_shot">Cobrar One Shot</label>
            <FormInput
              defaultValue={this.props.amount}
              id="charge_one_shot_amount"
              onChange={(event) => {
                this.setState({
                  data: { ...this.state.data, amount: parseFloat(event.target.value) }
                });
              }}
              type="number"
            />
          </Col>
          <Col xs="6">
            <label htmlFor="charge_one_shot_tax_consumption">Impuestos</label>
            <FormInput
              defaultValue={this.props.taxConsumption}
              id="charge_one_shot_tax_consumption"
              onChange={(event) => {
                this.setState({
                  data: { ...this.state.data, taxConsumption: parseFloat(event.target.value) }
                });
              }}
              type="number"
            />
          </Col>
        </FormGroup>
        <Button type="submit">Cobrar</Button>
      </Form>
    );
  }
}
