const config = {
  dev: {
    rollbarToken: '27edc62d4e4d4e5781a9f5a7abf05ef5'
  },
  staging: {
    rollbarToken: '27edc62d4e4d4e5781a9f5a7abf05ef5'
  },
  production: {
    rollbarToken: '27edc62d4e4d4e5781a9f5a7abf05ef5'
  }
};

const currentConfig = config[process.env.REACT_APP_STAGE || 'dev'];

export default currentConfig;
export { currentConfig as config };
