import React from 'react';
import { Form, FormGroup, Button, FormCheckbox } from 'shards-react';

export default class IntegrationByApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { integrationByApi: props.subscription.integrationByApi }
    };
  }
  render() {
    const { integrationByApi } = this.state.data.integrationByApi;
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.props
            .mutate({
              variables: { id: this.props.id, integrationByApi: this.state.data.integrationByApi }
            })
            .then(() => {
              this.props.onSuccess();
            })
            .catch((error) => {
              this.props.onError(error);
            });
        }}
      >
        <FormGroup>
          <label htmlFor="integration_by_api">Integrado por API</label>
          <FormCheckbox
            checked={this.state.data.integrationByApi}
            id="integrationByApi"
            onChange={() => {
              this.setState({ data: { integrationByApi: !integrationByApi } });
            }}
            toggle
          >
            {integrationByApi
              ? 'La suscripción está integrada por API'
              : 'La suscripción NO está integrada por API'}
          </FormCheckbox>
        </FormGroup>
        <Button type="submit">Actualizar</Button>
      </Form>
    );
  }
}
