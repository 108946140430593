import React, { useMemo } from 'react';
import { Nav, NavItem, NavLink } from 'shards-react';
import styled from 'styled-components';

import SidebarNavItem from './SidebarNavItem';
import items from '../../data/sidebar-nav-items';
import { useEmail } from '../../contexts/AuthContext';
import { useAuthorize } from '../../permissions/helpers';
import GlobalSearch from '../../components/GlobalSearch';

const FixedWidthLink = styled(NavLink)`
  width: 1vw;
`;

const SidebarNavItems = () => {
  const email = useEmail();
  const isAuthorized = useAuthorize();

  const userItems = useMemo(() => {
    return items.filter((item) => isAuthorized(item.permission));
  }, [isAuthorized]);

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {userItems.map((item, idx) => (
          <SidebarNavItem item={item} key={idx} />
        ))}
      </Nav>

      <Nav className="nav--no-borders flex-column">
        <NavItem>
          <GlobalSearch />
        </NavItem>
      </Nav>

      <Nav style={{ position: 'absolute', bottom: 0 }}>
        <NavItem>
          <FixedWidthLink>
            <div className="d-inline-block item-icon-wrapper">
              <i className="material-icons">perm_identity</i>
            </div>
            <span title={email}>{email}</span>
          </FixedWidthLink>
        </NavItem>
        <NavItem>
          <NavLink href="https://admin.increase.app/admin">
            <div className="d-inline-block item-icon-wrapper">
              <i className="material-icons">exit_to_app</i>
            </div>
            <span>Ir a Card Admin</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="https://auth.increase.app/logout">
            <div className="d-inline-block item-icon-wrapper">
              <i className="material-icons">power_settings_new</i>
            </div>
            <span>Cerrar sesión</span>
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default SidebarNavItems;
