import React from 'react';
import { Container, Row, Card, CardBody, Alert } from 'shards-react';

import PageTitle from '../../layouts/PageTitle';
import MutationInviteUser from '../../mutations/MutationInviteUser';
import InviteForm from './InviteForm';
import ServerError from '../ServerError';

export default class InviteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      change_success: false
    };
  }

  successChange = () => {
    this.setState({ change_success: true });
  };

  onError = (error) => {
    this.setState({ error, change_success: false });
  };

  render() {
    const user = this.props.user;
    return (
      <Container fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title={this.props.title} />
        </Row>
        <Card>
          <CardBody>
            {this.state.change_success && (
              <Alert theme="success">Invitación creada correctamente</Alert>
            )}

            <ServerError error={this.state.error} />

            <MutationInviteUser>
              {(mutate) => {
                return (
                  <InviteForm
                    user={user}
                    mutate={mutate}
                    onError={this.onError}
                    onSuccess={this.successChange}
                  />
                );
              }}
            </MutationInviteUser>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
