import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';
import SubscriptionFragments from '../queries/fragments/SubscriptionFragments';

export default function MutationChangeSubscriptionContactCenter({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation ChangeSubscriptionContactCenter($id: String!, $contactCenterId: String!) {
          subscription: changeSubscriptionContactCenter(
            id: $id
            contactCenterId: $contactCenterId
          ) {
            ...SubscriptionFields
          }
        }
        ${SubscriptionFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
