import { useCallback } from 'react';
import { isEmpty } from 'lodash';

import { useRole } from '../contexts/AuthContext';
import { permissions } from './permissions';

const can = (userOrRole, action, data) => {
  const rolePermissions = permissions[userOrRole.role || userOrRole];

  if (!rolePermissions) {
    return false;
  }

  if (typeof rolePermissions[action] === 'function') {
    return rolePermissions[action](data);
  }
  return rolePermissions[action] === true;
};

// Hook
export const useAuthorize = () => {
  const role = useRole();
  const isAuthorized = useCallback(
    (permission, data) => {
      if (!role || !permission) {
        return false;
      }

      return can(role, permission, data);
    },
    [role]
  );

  return isAuthorized;
};

// React component
export const Can = ({ children, action, data, allowIfEmpty }) => {
  const isAuthorized = useAuthorize();

  if (allowIfEmpty && isEmpty(action)) {
    return children;
  }

  if (isAuthorized(action, data)) {
    return children;
  }

  return null;
};
