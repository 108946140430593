import React from 'react';
import { FormGroup, FormInput, FormSelect, Row, Col } from 'shards-react';
import styled from 'styled-components';

const ContryTelCode = styled(FormSelect)`
  width: 80px;
`;

export const PhoneInput = ({ onChange, phoneCountryCode, countries = [] }) => {
  return (
    <FormGroup>
      <label htmlFor="lastName">Teléfono</label>
      <Row>
        <Col xs="auto">
          <ContryTelCode col disabled value={phoneCountryCode}>
            <option value=""> -- </option>
            {countries.map(({ telCode }) => (
              <option key={telCode} value={telCode}>
                {telCode}
              </option>
            ))}
          </ContryTelCode>
        </Col>
        <Col>
          <FormInput
            col
            id="phone"
            onChange={(event) => onChange(event.target.value)}
            type="tel"
            pattern="[0-9]*"
            placeholder="Teléfono con código de área"
            required
          />
        </Col>
      </Row>
    </FormGroup>
  );
};
