import React, { useState } from 'react';
import { Form, FormGroup, FormSelect, Button } from 'shards-react';
import QueryPartners from '../../queries/QueryPartners';

export default function ChangeSubscriptionContactCenter(props) {
  const [contactCenterId, setContactCenterId] = useState('');

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        props
          .mutate({ variables: { id: props.id, contactCenterId } })
          .then(() => props.onSuccess())
          .catch(props.onError);
      }}
    >
      <FormGroup>
        <QueryPartners>
          {(contactCenters) => (
            <FormSelect
              defaultValue={contactCenterId}
              id="update-contactCenter"
              onChange={(event) => setContactCenterId(event.target.value)}
            >
              <option value="">-- Cambiar Contact center ---</option>
              {contactCenters.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
            </FormSelect>
          )}
        </QueryPartners>
      </FormGroup>

      <Button type="submit" disabled={!contactCenterId}>
        Actualizar Contact center
      </Button>
    </Form>
  );
}
