import React from 'react';
import { FormGroup, FormSelect } from 'shards-react';

import QueryPartners from '../../queries/QueryPartners';

export const PartnerSelect = ({ onChange, required, defaultValue }) => {
  return (
    <QueryPartners>
      {(partners) => (
        <FormGroup>
          <label htmlFor="partner">Partner*</label>
          <FormSelect
            defaultValue={defaultValue}
            onChange={(event) => onChange(event.target.value)}
            required={required}
          >
            <option value="">-- Seleccione un partner --</option>
            {partners.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </FormSelect>
        </FormGroup>
      )}
    </QueryPartners>
  );
};
