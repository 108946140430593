import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';

export default function MutationInviteUser({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation InviteUser(
          $email: String!
          $firstName: String!
          $lastName: String!
          $phone: String!
          $leadNode: String!
          $product: String!
          $country: String!
          $salesOwnerId: String!
          $extraFields: [ExtraField!]
          $partnerId: String!
        ) {
          invite: inviteUser(
            email: $email
            firstName: $firstName
            lastName: $lastName
            phone: $phone
            leadNode: $leadNode
            product: $product
            country: $country
            salesOwnerId: $salesOwnerId
            extraFields: $extraFields
            partnerId: $partnerId
          ) {
            email
            error
          }
        }
      `}
    >
      {(mutate, result) => {
        return children(mutate, result);
      }}
    </SmartMutation>
  );
}
