import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';

import SubscriptionFragments from '../queries/fragments/SubscriptionFragments';

export default function MutationApplyDiscount({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation MutationApplyDiscount($subscriptionId: String!, $discountId: String) {
          subscription: applyDiscount(id: $subscriptionId, discountId: $discountId) {
            ...SubscriptionFields
          }
        }
        ${SubscriptionFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
