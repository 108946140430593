import React from 'react';
import { Form, FormGroup, Button, FormSelect, Col } from 'shards-react';

const CLOSED_WON_SELECT = {
  BUSINESS_CLOSED: 'Cierre de negocio',
  LEFT_BY_COMPETITION: 'Se fue a la competencia.',
  BAD_SALE: 'Mala venta',
  PRODUCT_NOT_WORKING: 'No le funcionó bien la plataforma',
  NEED_EXTRA_FEATURE: 'Necesita una función que Increase no tiene',
  TOO_EXPENSIVE_UNSUBSCRIBE: 'Le parece Caro / Problemas con el precio',
  LACK_OF_PAYMENT: 'Falta de pago',
  NO_USE: 'No lo uso',
  ALREADY_REGISTERED: 'Ya está registrado',
  CLOUDBLUE_CANCELLATION: 'Cancelación CloudBlue'
};

const CLOSED_LOST_SELECT = {
  INCREASE_NOT_WORKING: 'No le funciono bien la plataforma',
  EXPECTATIONS_TOO_HIGH: 'No cumple expectativas',
  LOST_CONTACT: 'No atendio mas / Perdimos contacto',
  TOO_EXPENSIVE: 'Le parecio Caro / Problemas con el Precio',
  TEST_LATER: 'Lo probará o evaluará mas adelante',
  ALREADY_REGISTERED: 'Ya está registrado',
  INVALID_CONTACT_INFO: 'Datos de contacto inválidos',
  LOOKING_MORE_INFO: 'Solo quería más información',
  CLOUDBLUE_CANCELLATION: 'Cancelación CloudBlue',
  PARTNER_USER_CANCELLATION: 'Cancelación de usuario partner',
  NOT_MEET_REQUERIMENTS: 'No cumple los requisitos'
};

const DEPRECATED_SELECTS = {
  INSUFFICIENT_TRIAL: 'No tuvo tiempo para usarlo',
  MISSING_PAYMENT_METHOD: 'No envio datos facturacion/cobro',
  AUTOMATIC_CLOSE: 'Cerrado Automaticamente',
  PAYMENT_METHOD_CHANGE: 'Cambio de Medio de Cobro',
  LEGAL_NAME_CHANGE: 'Cambio de razón social.',
  OTHER: 'Otros Motivos (Evitarlo lo máximo posible)',
  MISSUNDERSTAND_PRODUCT: 'Pensaba que era otro producto / servicio',
  PRODUCT_NOT_APPLY: 'No tiene comercio / No vende con tarjeta'
};

export const CLOSE_REASONS = {
  ...DEPRECATED_SELECTS,
  ...CLOSED_WON_SELECT,
  ...CLOSED_LOST_SELECT
};

export default class CancelLicensesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cancel_licenses: false };
  }

  closedWonSelect = () => {
    return Object.entries(CLOSED_WON_SELECT).map(([fieldName, message]) => {
      return (
        <option key={fieldName} value={fieldName}>
          {message}
        </option>
      );
    });
  };

  closedLostSelect = () => {
    return Object.entries(CLOSED_LOST_SELECT).map(([fieldName, message]) => {
      return (
        <option key={fieldName} value={fieldName}>
          {message}
        </option>
      );
    });
  };

  render() {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (!this.state.cancel_licenses) {
            alert('Debe chequear para confirmar la baja');
          } else {
            if (this.state.cancel_motive === undefined) {
              return alert('Debe seleccionar un motivo de baja');
            }
            this.props
              .mutate({
                variables: { id: this.props.id, motive: this.state.cancel_motive }
              })
              .then(
                (_) => {
                  this.props.onSuccess();
                },
                (error) => {
                  this.props.onError(error);
                }
              );
          }
        }}
      >
        <label>Dar de baja</label>
        <FormGroup row>
          <Col>
            <FormSelect
              id="cancel_motive"
              onChange={(event) => {
                this.setState({ cancel_motive: event.target.value });
              }}
              defaultValue=""
            >
              <option disabled value="">
                -- Seleccione un motivo de baja --
              </option>
              {this.props.subscription_state === 'usable'
                ? this.closedWonSelect()
                : this.closedLostSelect()}
            </FormSelect>
          </Col>
          <Col xs="auto">
            <label className="mt-2" htmlFor="cancel_licenses">
              Confirmar baja de producto
            </label>
            <input
              className="ml-3"
              id="cancel_licenses"
              onChange={(event) => {
                this.setState({ cancel_licenses: event.target.checked });
              }}
              type="checkbox"
            />
          </Col>
        </FormGroup>

        <Button type="submit">Efectuar la baja</Button>
      </Form>
    );
  }
}
