import React, { useState } from 'react';
import { ModalHeader, ModalBody, ModalFooter, Button, FormGroup, FormSelect } from 'shards-react';
import MutationUnqualifyInvitation from '../../mutations/MutationUnqualifyInvitation';
import ServerError from '../../components/ServerError';
import unqualifyReasons from '../unqualify-reasons';

export default function Unqualified({ data: invitation, toggle, refetch }) {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState();
  const [reason, setReason] = useState('');
  const [serverError, setServerError] = useState();

  const save = (mutate) => {
    mutate({ variables: { id: invitation.id, reason } }).then(
      ({ data: { success } }) => {
        if (success) {
          setError(false);
          setSent(true);
          setMsg('Se actualizó la invitación exitosamente.');
          refetch();
        } else {
          setError(true);
          setMsg('No se pudo guardar la invitación');
        }
      },
      (error) => {
        setError(true);
        setServerError(error);
      }
    );
  };

  return (
    <>
      <ModalHeader>Unqualified</ModalHeader>
      <ModalBody>
        <div className={error ? 'd-block invalid-feedback' : 'd-block valid-feedback'}>
          {!serverError && msg}
          {serverError && <ServerError error={serverError} />}
        </div>

        <FormGroup>
          <label htmlFor="reason">Motivo*</label>
          <FormSelect
            value={reason}
            id="reason"
            onChange={(event) => {
              setReason(event.target.value);
            }}
          >
            <option value="">-- Seleccionar Motivo ---</option>
            {unqualifyReasons.map((r) => (
              <option value={r.text}>{r.text}</option>
            ))}
          </FormSelect>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button theme="secondary" onClick={toggle}>
          Cerrar
        </Button>
        <MutationUnqualifyInvitation>
          {(mutate) => {
            return (
              !sent &&
              !error && (
                <Button disabled={!reason} onClick={() => save(mutate)}>
                  Guardar
                </Button>
              )
            );
          }}
        </MutationUnqualifyInvitation>
      </ModalFooter>
    </>
  );
}
