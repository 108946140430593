import gql from 'graphql-tag';

const CommentFragments = {};

CommentFragments.fields = gql`
  fragment CommentFields on Comment {
    id
    body
    productName
    writerEmail
    createdAt
  }
`;

export default CommentFragments;
