import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QuerySubscriptions({ filter, page, children }) {
  const threeStateValue = (value) => {
    if (value === 'indeterminate') {
      return false;
    }
    return value ? true : undefined;
  };

  const transformFilters = (filters) => {
    const {
      salesDataUpdated,
      onboardingFinished,
      billingDataUpdated,
      subscriptionValidated,
      ...rest
    } = filters;

    return {
      ...rest,
      events: {
        salesDataUpdated: threeStateValue(salesDataUpdated),
        onboardingFinished: threeStateValue(onboardingFinished),
        billingDataUpdated: threeStateValue(billingDataUpdated),
        subscriptionValidated: threeStateValue(subscriptionValidated)
      }
    };
  };

  return (
    <SmartQuery
      query={gql`
        query Subscriptions($filter: SubscriptionsFilter, $page: Int) {
          subscriptions(filter: $filter, page: $page) {
            id
            state
            country
            terminalsCount
            salesOwner {
              id
              email
            }
            createdAt
            product {
              id
              name
            }
            account {
              id
              name
            }
            partner {
              id
              name
            }
          }
        }
      `}
      variables={{ filter: transformFilters(filter), page: page }}
    >
      {(data) => children(data.subscriptions)}
    </SmartQuery>
  );
}
