import React from 'react';
import { useQuery } from '@apollo/client';

import ServerError from '../components/ServerError';

export default function SmartQuery({
  query,
  variables,
  children,
  fetchPolicy = 'cache-and-network'
}) {
  const { loading, error, data, refetch } = useQuery(query, { variables, fetchPolicy });
  if (loading) return <p>Loading...</p>;
  if (error) return <ServerError error={error} />;
  return children(data, refetch);
}
