import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormTextarea,
  FormGroup,
  Form,
  Col,
  Row
} from 'shards-react';

const CommentsModal = ({ openCommentBox, setOpenCommentBox, mutate, queryData, refetch }) => {
  const toggle = () => setOpenCommentBox(false);
  const [comment, setComment] = useState('');
  const [invalidComment, setInvalidComment] = useState(false);

  const isEnterKeyPressed = (event) => event.keyCode === 13 && !event.shiftKey;

  const handleEnter = (event) => {
    if (isEnterKeyPressed(event)) {
      sendComment();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEnter, false);
    return () => document.removeEventListener('keydown', handleEnter, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isInvalidComment = (newComment) => {
    if (newComment.trim().length === 0) {
      return true;
    }
    return false;
  };

  const handleWrite = (e) => {
    const newComment = e.target.value;
    setComment(newComment);
    setInvalidComment(isInvalidComment(newComment));
  };

  const sendComment = () => {
    if (isInvalidComment(comment)) {
      setInvalidComment(true);
      return;
    }
    toggle();
    mutate({
      variables: { comment: { accountId, subscriptionId, productName, body: comment } }
    }).then(() => {
      refetch();
    });
  };

  if (!queryData) return;

  const accountId = (queryData.account && queryData.account.id) || queryData.id;

  const subscriptionId = queryData.account ? queryData.id : undefined;

  const productName = subscriptionId ? queryData.product.name : undefined;

  return (
    <div>
      <Modal open={openCommentBox} toggle={toggle} centered={true}>
        <ModalHeader>Agregar un comentario</ModalHeader>
        <ModalBody>
          <Form onSubmit={sendComment}>
            <FormGroup>
              <FormTextarea
                id="comment-text-area"
                size="lg"
                invalid={invalidComment}
                onChange={handleWrite}
              />
            </FormGroup>
            <Row className="mt-3">
              <Col className="text-right">
                <Button theme="secondary" className="mr-3" onClick={toggle}>
                  Cancelar
                </Button>
                <Button onClick={sendComment}>Guardar</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CommentsModal;
