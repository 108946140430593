import DeletableAttributeField from '../../components/fields/list/DeletableAttributeField';
import ListDateField from '../../components/fields/list/DateField';
import ShowTextField from '../../components/fields/show/TextField';
import ShowDateField from '../../components/fields/show/DateField';
import UserNameField from '../../components/fields/list/UserNameField';
import AccountMigrationEvents from '../../components/fields/show/AccountMigrationEvents';
import ShowLinkToModelField from '../../components/fields/show/LinkToModelField';

const accountMigrationFields = {
  list: {
    from: { label: 'Cuenta Origen', content: DeletableAttributeField('name') },
    to: { label: 'Cuenta Destino', content: DeletableAttributeField('name') },
    author: { label: 'Creador', content: DeletableAttributeField('email') },
    createdAt: { label: 'Fecha de creación', content: ListDateField }
  },
  show: {
    id: { label: 'ID', content: ShowTextField },
    from: {
      label: 'Cuenta Origen',
      type: 'object',
      content: {
        id: { label: 'ID', content: ShowLinkToModelField('/accounts') },
        name: { label: 'Nombre', content: ShowTextField },
        taxId: { label: 'Tax ID', content: ShowTextField },
        createdAt: { label: 'Fecha de creación', content: ShowDateField },
        deletedAt: { label: 'Fecha de borrado', content: ShowDateField }
      }
    },
    to: {
      label: 'Cuenta Destino',
      type: 'object',
      content: {
        id: { label: 'ID', content: ShowLinkToModelField('/accounts') },
        name: { label: 'Nombre', content: ShowTextField },
        taxId: { label: 'Tax ID', content: ShowTextField },
        createdAt: { label: 'Fecha de creación', content: ShowDateField },
        deletedAt: { label: 'Fecha de borrado', content: ShowDateField }
      }
    },
    author: {
      label: 'Autor',
      type: 'object',
      content: {
        id: { label: 'ID', content: ShowLinkToModelField('/users') },
        email: { label: 'Email', content: ShowTextField },
        name: { label: 'Nombre', content: UserNameField, virtual: true }
      }
    },
    events: {
      label: 'Eventos',
      content: AccountMigrationEvents
    }
  },
  search: [
    { key: 'toId', label: 'Cuenta Origen', type: 'account' },
    {
      key: 'fromId',
      label: 'Cuenta Destino',
      type: 'account'
    },
    { key: 'authorEmail', label: 'Email del creador' }
  ],
  buttons: [{ path: 'new', label: 'Nueva migracion' }]
};

export default accountMigrationFields;
