import gql from 'graphql-tag';

import TaskFragments from './TaskFragments';

const AccountFragments = {};

AccountFragments.fields = gql`
  fragment AccountFields on Account {
    id
    name
    taxId
    createdAt
    manualScraper
    enterprise
    users {
      id
      email
      owner
      phoneNumber
      firstName
      lastName
    }
    subscriptions {
      id
      product {
        id
        name
      }
      state
      terminalsCount
      country
    }
    payments {
      id
      orderDate
      type
      status
      country
      amount
    }
    invoices {
      id
      emissionDate
      amount
      taxAmount
      country
      status
      expirationDate
      externalDocumentLink
    }
    tasks {
      ...TaskSublistFields
    }
    accountBillingStates {
      id
      country
      balance
      inDebtSince
    }
  }
  ${TaskFragments.sublistFields}
`;

export default AccountFragments;
