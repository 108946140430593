import React from 'react';
import { Link } from 'react-router-dom';

import ResultTable from './ResultTable';

const Invitations = ({ data }) => {
  if (!data || data.length === 0) {
    return <span>No hay invitations para mostrar.</span>;
  }

  return (
    <ResultTable className="table">
      <tr>
        <th>Email</th>
        <th>Creador</th>
        <th>Estado</th>
        <th></th>
      </tr>
      {data.map((invitation) => (
        <tr>
          <td>
            <Link to={`invitations/${invitation.id}`}>{invitation.inviteeEmail}</Link>
          </td>
          <td>
            {invitation.inviter.email} {invitation.inviter.firstName} {invitation.inviter.lastName}
          </td>
          <td>{invitation.state.label}</td>
          <td className="actions">
            <Link to={`invitations/${invitation.id}`}>Ver</Link>
          </td>
        </tr>
      ))}
    </ResultTable>
  );
};

export default Invitations;
