import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QuerySearch({ q, children }) {
  return (
    <SmartQuery
      query={gql`
        query Search($q: String!) {
          search(q: $q) {
            accounts {
              id
              name
              createdAt
              users {
                id
                firstName
                lastName
                email
              }
            }
            invitations {
              id
              inviter {
                id
                firstName
                lastName
                email
              }
              inviteeEmail
              state {
                id
                name
                label
              }
            }
            tasks {
              id
              priority {
                id
                name
              }
              creator {
                id
                email
              }
              assignedTo {
                id
                email
              }
              resolutionType {
                id
                name
              }
            }
          }
        }
      `}
      variables={{ q }}
    >
      {(data) => children(data.search)}
    </SmartQuery>
  );
}
