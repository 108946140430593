const reasons = [
  { text: 'Me pareció Caro / Problemas de precio' },
  { text: 'Se confundio / Registro por Error (buscaba otra cosa)' },
  { text: 'Ya es cliente / Se registró con otros datos' },
  { text: 'Sin comercio / profesión / tareas relacionadas' },
  { text: 'Buscaba otro tipo de solución / funcionalidad' },
  { text: 'En otro momento' },
  { text: 'No vende con tarjeta' },
  { text: 'Pensó que era gratis' },
  { text: 'Pocas ventas' },
  { text: 'Alega no haberse registrado' },
  { text: 'Otros Motivos' }
];

export default reasons;
