import React from 'react';
import { FormGroup, FormSelect } from 'shards-react';

import QueryAppUsers from '../../queries/QueryAppUsers';
import { Role } from '../../roles';

export const SalesmanSelect = ({ onChange, user }) => {
  return (
    <FormGroup>
      <label htmlFor="salesOwnerId">Asignar a vendedor</label>
      <FormSelect
        id="salesOwnerId"
        onChange={(event) => onChange(event.target.value)}
        disabled={![Role.CommercialAdmin, Role.ContactCenterAdmin].includes(user.role)}
      >
        {[Role.CommercialAdmin, Role.ContactCenterAdmin].includes(user.role) ? (
          <QueryAppUsers>
            {(users) => (
              <React.Fragment>
                <option value={user.id} key="default">
                  {user.email}
                </option>
                {users.map((u) => (
                  <option key={u.id} value={u.id}>
                    {u.email}
                  </option>
                ))}
              </React.Fragment>
            )}
          </QueryAppUsers>
        ) : (
          <option value={user.id}>{user.email}</option>
        )}
      </FormSelect>
    </FormGroup>
  );
};
