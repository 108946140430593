import React from 'react';

export default function ChangeField({ value }) {
  return (
    <td>
      <table className="comparisionTable">
        {Object.entries(value).map(([key, value]) => {
          return (
            <tr>
              <td>{key}:</td>
              <td className="bold">{JSON.stringify(value)}</td>
            </tr>
          );
        })}
      </table>
    </td>
  );
}
