import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';
import SubscriptionFragments from '../queries/fragments/SubscriptionFragments';

export default function MutationCreateSubscription({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation MutationCreateSubscription(
          $accountId: String!
          $productId: String!
          $country: String!
        ) {
          subscription: createSubscription(
            accountId: $accountId
            productId: $productId
            country: $country
          ) {
            ...SubscriptionFields
          }
        }
        ${SubscriptionFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
