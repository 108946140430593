import React from 'react';
import { Container, Card, CardBody, Row } from 'shards-react';

import PageTitle from '../layouts/PageTitle.js';
import Table from './Table';
import Paginate from './Paginate';
import SearchBar from './search/SearchBar';
import ActionButton from './ActionButtons';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      filter: {}
    };
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.searchKeys === this.props.searchKeys) return;
    this.setState({ filter: {}, page: 1 });
  }

  handlePageClick = (direction) => {
    const page = this.state.page + direction;
    this.setState({ page: page < 1 ? 1 : page });
  };

  handleSearchBarUserSubmit = (filter) => {
    this.setState((state) => {
      return {
        filter: filter ? filter : state.filter,
        page: 1
      };
    });
  };

  render() {
    return (
      <Container fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title={this.props.title} />
        </Row>
        <Card>
          <CardBody>
            <Row>
              {this.props.searchKeys && (
                <SearchBar
                  fields={this.props.searchKeys}
                  onSubmit={this.handleSearchBarUserSubmit}
                />
              )}
              <hr />
              {this.props.buttons &&
                this.props.buttons.map(({ path, label }, index) => {
                  return (
                    <ActionButton
                      path={this.props.location.pathname + '/' + path}
                      label={label}
                      key={index}
                      className="ml-3"
                    />
                  );
                })}
            </Row>
            <this.props.queryData filter={this.state.filter} page={this.state.page}>
              {(data, refetch) => {
                return (
                  <React.Fragment>
                    <hr />
                    <Table
                      customLinks={this.props.customLinks || []}
                      data={data}
                      editable={this.props.editable}
                      fields={this.props.fields}
                      links={true}
                      path={this.props.location.pathname}
                      refetch={refetch}
                    />
                    <hr />
                    <Paginate currentPage={this.state.page} onPageChange={this.handlePageClick} />
                  </React.Fragment>
                );
              }}
            </this.props.queryData>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default List;
