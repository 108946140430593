import React, { useState, useMemo } from 'react';
import { Form, FormGroup, Button, FormSelect, Badge } from 'shards-react';

import QueryDiscounts from '../../queries/QueryDiscounts';
import RemoveDiscount from './RemoveDiscount';

const discountsWithLabel = (discount) => {
  const amountStr = discount.percentage ? discount.amount * 100 : discount.amount;

  let str = `${discount.name} ${discount.percentage ? '' : '$'}`;
  str += `${amountStr}${discount.percentage ? '%' : ''}`;
  str += ` por ${discount.recurringCycles} meses`;
  str += ` (quedan ${discount.recurringCyclesLeft})`;

  return { label: str, id: discount.id };
};

export default function EditDiscountForm({
  subscriptionId,
  onSubmit,
  onSuccess,
  onError,
  currentDiscounts = []
}) {
  const [discountId, setDiscountId] = useState('');

  const onSelectDiscount = (event) => {
    setSent(false);
    setDiscountId(event.target.value);
  };

  const [sent, setSent] = useState(false);

  const discountLabels = useMemo(() => {
    if (currentDiscounts.length === 0) {
      return [{ label: 'No aplica descuento', id: 0 }];
    }
    return currentDiscounts.map(discountsWithLabel);
  }, [currentDiscounts]);

  const buttonText = useMemo(() => {
    if (!discountId && currentDiscounts.length > 0) {
      return 'Remover descuentos actuales';
    }

    return 'Aplicar nuevo descuento';
  }, [currentDiscounts, discountId]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        setSent(true);
        onSubmit({ variables: { subscriptionId, discountId } })
          .then(() => onSuccess())
          .catch((error) => onError(error));
      }}
    >
      <FormGroup>
        <label htmlFor="discounts">
          Descuentos actuales:
          <br />
          {discountLabels.map((discount, idx) => (
            <Badge key={idx} theme="secondary" className="mr-1">
              {discount.label}
              {discount.id !== 0 && (
                <RemoveDiscount
                  subscriptionId={subscriptionId}
                  discountId={discount.id}
                  onSuccess={onSuccess}
                />
              )}
            </Badge>
          ))}
        </label>
        <br />
        <QueryDiscounts subscriptionId={subscriptionId}>
          {(discounts) => (
            <FormSelect defaultValue={discountId} onChange={onSelectDiscount}>
              <option value="">-- No aplica descuento ---</option>
              {discounts.map((discount) => (
                <option key={discount.id} value={discount.id}>
                  {discount.label}
                </option>
              ))}
            </FormSelect>
          )}
        </QueryDiscounts>
      </FormGroup>
      <Button disabled={sent} type="submit">
        {buttonText}
      </Button>
    </Form>
  );
}
