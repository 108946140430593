import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QueryAccountMigrations({ filter, page, children }) {
  return (
    <SmartQuery
      query={gql`
        query AccountMigrations($filter: AccountMigrationsFilter, $page: Int) {
          accountMigrations(filter: $filter, page: $page) {
            id
            from {
              name
              deletedAt
            }
            to {
              name
              deletedAt
            }
            author {
              email
              deletedAt
            }
            createdAt
          }
        }
      `}
      variables={{ filter: filter, page: page }}
    >
      {(data) => children(data.accountMigrations)}
    </SmartQuery>
  );
}
