import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';

import SubscriptionFragments from '../queries/fragments/SubscriptionFragments';

export default function MutationEmitHuntingEvent({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation EmitHuntingEvent($id: String!, $event: String!) {
          subscription: emitHuntingEvent(id: $id, event: $event) {
            ...SubscriptionFields
          }
        }
        ${SubscriptionFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
