import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';

import SubscriptionFragments from '../queries/fragments/SubscriptionFragments';

export default function MutationUpdateBonusFree({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation UpdateBonusFree($id: String!, $bonusFree: Boolean!) {
          subscription: updateBonusFree(id: $id, bonusFree: $bonusFree) {
            ...SubscriptionFields
          }
        }
        ${SubscriptionFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
