import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery';
import UserFragments from './fragments/UserFragments';

export default function QueryUser({ id, children }) {
  return (
    <SmartQuery
      query={gql`
        query User($id: String!) {
          user(id: $id) {
            ...UserFields
          }
        }
        ${UserFragments.fields}
      `}
      variables={{ id }}
    >
      {(data) => children(data.user)}
    </SmartQuery>
  );
}
