import React from 'react';
import { Alert } from 'shards-react';

import { useErrorTracker } from '../contexts/ErrorTrackingContext';

const ServerError = (props) => {
  const errorTracker = useErrorTracker();
  if (!errorTracker) return null;

  if (!props.error) {
    return null;
  }

  const graphQLErrors = props.error.graphQLErrors || [];
  let errorMessage = 'Ha ocurrido un error inesperado.';

  const errors = graphQLErrors.map(({ message, extensions }, i) => {
    let details = [];

    if (extensions.code.startsWith('PARTNER_ADMIN/')) {
      errorMessage = message;
      if (extensions.exception && extensions.exception.errorMessages) {
        details = extensions.exception.errorMessages;
      }
    }

    errorTracker.error(errorMessage, details);

    return (
      <Alert key={i} theme="danger">
        {errorMessage}
        <ul className="mb-0">
          {details.map((d, idx) => (
            <li key={idx}>{d}</li>
          ))}
        </ul>
      </Alert>
    );
  });

  if (errors.length === 0) {
    errorTracker.error(errorMessage);
    errors.push(
      <Alert key={0} theme="danger">
        {errorMessage}
      </Alert>
    );
  }

  return errors;
};

export default ServerError;
