import React from 'react';
import { Link } from 'react-router-dom';

import ResultTable from './ResultTable';
import DateField from '../fields/list/DateField';

const Accounts = ({ data }) => {
  if (!data || data.length === 0) {
    return <span>No hay cuentas para mostrar.</span>;
  }

  return (
    <ResultTable className="table">
      <tr>
        <th>Nombre</th>
        <th>Fecha de creación</th>
        <th></th>
      </tr>
      {data.map((account) => (
        <>
          <tr>
            <td>
              <Link to={`accounts/${account.id}`}>{account.name}</Link>
            </td>
            <DateField value={account.createdAt} />
            <td className="actions">
              <Link to={`accounts/${account.id}`}>Ver</Link>
            </td>
          </tr>
          {account.users.length > 0 && (
            <tr>
              <td colSpan="3">
                <ul className="my-0">
                  {account.users.map((user) => (
                    <li>
                      {user.email} {user.firstName} {user.lastName}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          )}
        </>
      ))}
    </ResultTable>
  );
};

export default Accounts;
