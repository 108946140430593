import React from 'react';

export default function DeletableAttributeField(attribute) {
  return function({ value }) {
    return value.deletedAt ? (
      <td style={{ color: 'red' }}>{value && `${value[attribute]}-(deleted)`}</td>
    ) : (
      <td>{value && value[attribute]}</td>
    );
  };
}
