import React from 'react';
import gql from 'graphql-tag';

import SmartMutation from './SmartMutation';
import TaskFragments from '../queries/fragments/TaskFragments';

export default function MutationUpdateTask({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation UpdateTask(
          $id: String
          $priorityId: String
          $assignedToId: String
          $resolutionTypeId: String
          $dueAt: DateTime
          $description: String
          $options: TaskOptions
        ) {
          task: updateTask(
            id: $id
            priorityId: $priorityId
            assignedToId: $assignedToId
            resolutionTypeId: $resolutionTypeId
            dueAt: $dueAt
            description: $description
            options: $options
          ) {
            ...TaskFields
          }
        }
        ${TaskFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
