import React from 'react';
import ReactJson from 'react-json-view';

export default function({ value, ...initProps }) {
  const props = {
    name: null,
    indentWidth: 2,
    displayObjectSize: false,
    displayDataTypes: false,
    enableClipboard: false,
    ...initProps
  };
  if (typeof value === 'string') props.src = JSON.parse(value);
  if (typeof value === 'object') props.src = value;

  return <ReactJson {...props} />;
}
