import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';
import InvitationFragments from './fragments/InvitationFragments.js';

export default function QueryInvitations({ filter, page, children }) {
  return (
    <SmartQuery
      query={gql`
        query Invitations($filter: InvitationsFilter, $page: Int) {
          invitations(filter: $filter, page: $page) {
            ...InvitationFields
          }
        }
        ${InvitationFragments.fields}
      `}
      variables={{ filter: filter, page: page }}
    >
      {(data, refetch) => children(data.invitations, refetch)}
    </SmartQuery>
  );
}
