import ShowTextField from '../../components/fields/show/TextField';
import ShowDateField from '../../components/fields/show/DateField';
import AttributeField from '../../components/fields/list/AttributeField';
import TextArea from '../../components/fields/show/TextArea';
import ModelLink from '../../components/fields/show/ModelLink';
import TaskResolution from '../../components/fields/list/TaskResolution';
import TruncatedEmail from '../../components/fields/list/TruncatedEmail';

const taskFields = {
  list: {
    priority: { label: 'Prioridad', content: AttributeField('name') },
    creator: { label: 'Creador', content: TruncatedEmail('email') },
    assignedTo: { label: 'Asignado', content: TruncatedEmail('email') },
    dueAt: { label: 'Vencimiento', content: ShowDateField },
    associatedTo: { label: 'Asociada a', content: ShowTextField },
    resolutionType: { label: 'Resuelta?', content: TaskResolution }
  },
  show: {
    id: { label: 'ID', content: ShowTextField },
    type: {
      label: 'Tipo',
      type: 'object',
      content: {
        name: { content: ShowTextField }
      }
    },
    priority: {
      label: 'Prioridad',
      type: 'object',
      content: {
        name: { content: ShowTextField }
      }
    },
    creator: {
      label: 'Creador',
      type: 'object',
      content: {
        id: { label: 'ID', content: ShowTextField },
        email: { label: 'email', content: ShowTextField }
      }
    },
    assignedTo: {
      label: 'Asignado',
      type: 'object',
      content: {
        firstName: { label: 'Nombre', content: ShowTextField },
        lastName: { label: 'Apellido', content: ShowTextField },
        email: { label: 'email', content: ShowTextField }
      }
    },
    dueAt: { label: 'Vencimiento', content: ShowDateField },
    associatedTo: { label: 'Asociada a', content: ModelLink, virtual: true },
    resolutionType: {
      label: 'Resolución',
      type: 'object',
      content: {
        name: { content: ShowTextField }
      }
    },
    description: { label: 'Description', content: TextArea }
  },
  search: [{ key: 'assignedToEmail', label: 'Asignado a' }]
};

export default taskFields;
