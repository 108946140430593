import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col } from 'shards-react';

const ActionButton = ({ label, path, className, theme }) => {
  return (
    <Col xs="auto" className={className}>
      <Link to={path}>
        <Button theme={theme}>{label}</Button>
      </Link>
    </Col>
  );
};

export default ActionButton;
