import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';
import TaskFragments from './fragments/TaskFragments.js';
import SubscriptionFragments from './fragments/SubscriptionFragments.js';
import Comment from './fragments/CommentFragments.js';

export default function QuerySubscription({ id, children }) {
  return (
    <SmartQuery
      query={gql`
        query Subscription($id: String!) {
          subscription(id: $id) {
            ...SubscriptionFields
            tasks {
              ...TaskSublistFields
            }
            comments {
              ...CommentFields
            }
          }
        }
        ${TaskFragments.sublistFields}
        ${SubscriptionFragments.fields}
        ${Comment.fields}
      `}
      variables={{ id: id }}
    >
      {(data, refetch) => children(data.subscription, refetch)}
    </SmartQuery>
  );
}
