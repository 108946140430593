import React from 'react';
import BooleanField from '../show/BooleanField';

export default function TableBooleanField(props) {
  return (
    <td>
      <BooleanField {...props} />
    </td>
  );
}
