import React from 'react';
import { Badge, Button, FormGroup, Form } from 'shards-react';

const UpdateBonusFree = ({ mutate, subscription, onSuccess, onError }) => {
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        mutate({
          variables: {
            id: subscription.id,
            bonusFree: !subscription.bonusFree
          }
        })
          .then(() => onSuccess())
          .catch((error) => onError(error));
      }}
    >
      <FormGroup>
        <label className="d-block">Bonificaciones</label>
        <label>Libre de bonificación:</label>
        <Badge className="ml-3" theme={subscription.bonusFree ? 'success' : 'danger'}>
          {subscription.bonusFree ? 'Si' : 'No'}
        </Badge>
      </FormGroup>
      <Button type="submit">Cambiar</Button>
    </Form>
  );
};

export default UpdateBonusFree;
