import React, { useState } from 'react';
import { ModalHeader, ModalBody, ModalFooter, Button } from 'shards-react';
import MutationSendInvitation from '../../mutations/MutationSendInvitation';

export default function SendInvite({ data: invitation, toggle, refetch }) {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const send = (mutate) => {
    mutate({ variables: { id: invitation.id } }).then(
      ({ data: { response } }) => {
        if (response.sent) {
          setSent(true);
          refetch();
        } else {
          let msg = 'No se pudo enviar la invitación';
          if (response.invitation.acceptedByOther) {
            msg = 'La invitación ya ha sido aceptada por otro partner.';
          }
          setError(msg);
        }
      },
      (_error) => {
        setError('No se pudo enviar la invitación');
      }
    );
  };

  if (invitation.acceptedByOther) {
    return (
      <>
        <ModalHeader>La invitación ya ha sido aceptada por otro partner.</ModalHeader>
        <ModalFooter>
          <Button onClick={toggle}>Cerrar</Button>
        </ModalFooter>
      </>
    );
  }

  return (
    <>
      <ModalHeader>Enviar invitación</ModalHeader>
      <ModalBody>
        {!sent && !error && 'Esto enviará un email al usuario...'}
        {sent && 'La invitacion ha sido enviada con éxito.'}
        {error}
      </ModalBody>
      <ModalFooter>
        <Button theme="secondary" onClick={toggle}>
          Cerrar
        </Button>
        <MutationSendInvitation>
          {(mutate) => {
            return !sent && !error && <Button onClick={() => send(mutate)}>Enviar</Button>;
          }}
        </MutationSendInvitation>
      </ModalFooter>
    </>
  );
}
