import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';
import TaskFragments from './fragments/TaskFragments.js';

export default function QueryTask({ id, children }) {
  return (
    <SmartQuery
      query={gql`
        query Task($id: String!) {
          task: task(id: $id) {
            ...TaskFields
          }
        }
        ${TaskFragments.fields}
      `}
      variables={{ id: id }}
    >
      {(data) => children(data.task)}
    </SmartQuery>
  );
}
