import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';
import SubscriptionFragments from '../queries/fragments/SubscriptionFragments';

export default function MutationExtendFreeTrialPeriod({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation ExtendFreeTrialPeriod($id: String!, $date: DateTime) {
          subscription: extendFreeTrialPeriod(id: $id, date: $date) {
            ...SubscriptionFields
          }
        }
        ${SubscriptionFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
