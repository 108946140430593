import React, { useMemo } from 'react';
import { isEmpty, compact, isArray } from 'lodash';

import { Link } from 'react-router-dom';
import ModalAction from './fields/list/ModalAction';
import { useRole } from '../contexts/AuthContext';
import { useAuthorize } from '../permissions/helpers';

export default function Table({ data, fields, path, editable, links, customLinks, refetch }) {
  const DEFAULT_PATH = '/users';
  const role = useRole();
  const isAuthorized = useAuthorize();

  const isEditable = useMemo(() => {
    if (editable && isArray(editable)) {
      return editable.includes(role);
    }

    return editable || false;
  }, [role, editable]);

  // Transform object in array and filter by permissions
  const roleFields = useMemo(() => {
    return compact(
      Object.entries(fields).map(([fieldName, field]) =>
        isEmpty(field.permission) || isAuthorized(field.permission) ? { ...field, fieldName } : null
      )
    );
  }, [isAuthorized, fields]);

  return (
    <React.Fragment>
      <table className="table mb-0 table-hover">
        <thead className="bg-light">
          <tr>
            {roleFields.map(({ label }, index) => {
              return (
                <th className="border-0" key={index} scope="col">
                  {label}
                </th>
              );
            })}

            {links && <th className="border-0" scope="col" />}
            {links && isEditable && <th className="border-0" scope="col" />}

            {(customLinks || []).map((_, index) => (
              <th className="border-0" key={index} scope="col" />
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((element) => {
            if (path === '/') path = DEFAULT_PATH;
            return (
              <tr key={element.id}>
                {roleFields.map(({ fieldName, virtual, content: FieldComponent }, index) => {
                  return (
                    <FieldComponent value={virtual ? element : element[fieldName]} key={index} />
                  );
                })}
                {links && (
                  <React.Fragment>
                    <td>
                      <Link to={`${path}/${element.id}`}>Ver</Link>
                    </td>
                    {isEditable && (
                      <td>
                        <Link to={`${path}/${element.id}/edit`}>Editar</Link>
                      </td>
                    )}
                  </React.Fragment>
                )}
                {(customLinks || []).map((props, index) => (
                  <ModalAction key={index} {...props} data={element} refetch={() => refetch()} />
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}
