const platformEvents = {
  sales_data_updated: 'Datos de contacto',
  onboarding_finished: 'Producto OK',
  subscription_validated: 'Carta OK',
  billing_data_updated: 'Actualizó información de pago',
  start_trial: 'Pasó a estado "Probando"',
  await_payment_data: 'Pasó a estado "Esperando información de pago"',
  mark_as_usable: 'Pasó a estado "Usando la plataforma"',
  one_shot_charged: 'One Shot cobrado',
  canceled: 'Pasó a estado "Cancelado"',
  cancel: 'Pasó a estado "Cancelado"',
  subscription_created: 'Suscripcion creada',
  awaiting_payment_data: 'Pasó a estado "Esperando información de pago"',
  ended_trial: 'Finalizo el periodo de prueba',
  free_trial: 'Comenzo el periodo de prueba',
  mrr_changed: 'El mrr ha sido modificado',
  usable: 'Paso a estado usable'
};

export const huntingEvents = {
  lead: 'Lead',
  lead_contacted: 'Contacted Lead',
  meeting_generated: 'Reunión Generada',
  proposal_sent: 'Propuesta Enviada',
  proposal_accepted: 'Propuesta Aceptada',
  files_received: 'Archivos Recibidos',
  implementing: 'Implementando',
  closed_won: 'Closed Won',
  lost: 'Lost',
  renegotiating: 'Renegociando',
  testing: 'Probando',
  reping: 'Repingueada',
  call_again: 'Volver a llamar'
};

export default {
  ...platformEvents,
  ...huntingEvents
};
