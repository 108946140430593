import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QueryUsers({ partnerId, children }) {
  return (
    <SmartQuery
      query={gql`
        query CustomFields($partnerId: String) {
          customFields(partnerId: $partnerId) {
            id
            name
            label
            inputType
            attributes
            required
          }
        }
      `}
      variables={{ partnerId }}
    >
      {(data) => children(data.customFields)}
    </SmartQuery>
  );
}
