import React from 'react';
import { Container, Row, Card, CardBody } from 'shards-react';
import { Redirect } from 'react-router-dom';

import PageTitle from '../../layouts/PageTitle';
import MutationCreateTask from '../../mutations/MutationCreateTask';
import TaskForm from './TaskForm';
import ServerError from '../ServerError';

export default class NewTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = { change_success: false };
  }

  successChange = (task) => {
    this.setState({ change_success: true, createdTaskId: task.id });
  };

  errorChange = (error) => {
    this.setState({ error });
  };

  render() {
    if (this.state.createdTaskId) {
      return <Redirect to={`/tasks/${this.state.createdTaskId}`} />;
    }

    return (
      <Container fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title={this.props.title} />
        </Row>
        <Card>
          <CardBody>
            {this.state.error && <ServerError error={this.state.error} />}

            <MutationCreateTask>
              {(mutate) => (
                <TaskForm
                  mutate={mutate}
                  onSubmit={this.successChange}
                  onError={this.errorChange}
                  taskableId={this.props.match.params.taskableId}
                  taskableType={this.props.match.params.taskableType}
                />
              )}
            </MutationCreateTask>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
