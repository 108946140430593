import React from 'react';

import Table from '../../Table';
import ListTextField from '../list/TextField';
import ListDateField from '../list/DateField';
import ShowTextField from './TextField';
import ShowDateField from './DateField';
import Modal from '../../Modal';
import ShowBody from '../../ShowBody';
import JSONViewer from './JSONViewer';

export default function AccountMigrationEvents({ value: events }) {
  const eventsToRender = [];
  const serviceAdded = {};

  const parsedEvents = events.map((migratinoEvent) => {
    return {
      ...migratinoEvent,
      metadata: JSON.parse(migratinoEvent.metadata)
    };
  });

  const getEvent = (eventName, eventService) => {
    return parsedEvents.find(
      (migrationEvent) =>
        migrationEvent.name === eventName && migrationEvent.metadata.service === eventService
    );
  };

  const DetailModal = ({ data, toggle }) => {
    const creationEvent = getEvent('created', data.service);
    const failedEvent = getEvent('failed', data.service);
    const finishedEvent = getEvent('finished', data.service);

    return (
      <Modal tittle="Detalles del Evento">
        <ShowBody
          data={{
            service: data.service,
            creationDate: creationEvent && creationEvent.createdAt,
            failedDate: failedEvent && failedEvent.createdAt,
            finishedDate: finishedEvent && finishedEvent.createdAt,
            errors:
              failedEvent &&
              failedEvent.metadata &&
              JSON.stringify({ ...failedEvent.metadata.errors }, null, 2)
          }}
          fields={{
            service: { label: 'Servicio', content: ShowTextField },
            creationDate: { label: 'Fecha de creacion', content: ShowDateField },
            failedDate: { label: 'Fecha de falla', content: ShowDateField },
            finishedDate: { label: 'Fecha de finalizacion', content: ShowDateField },
            errors: { label: 'Errores', content: JSONViewer }
          }}
        />
      </Modal>
    );
  };

  parsedEvents
    .map((migratinoEvent) => {
      return {
        service: migratinoEvent.metadata.service,
        status: migratinoEvent.name,
        createdAt: migratinoEvent.createdAt
      };
    })
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .forEach((migratinoEvent) => {
      if (!serviceAdded[migratinoEvent.service]) {
        eventsToRender.push(migratinoEvent);
        serviceAdded[migratinoEvent.service] = true;
      }
    });
  return (
    <Table
      data={eventsToRender}
      fields={{
        service: { label: 'Servicio', content: ListTextField },
        status: { label: 'Estado', content: ListTextField },
        createdAt: { label: 'Creado', content: ListDateField }
      }}
      customLinks={[{ label: 'Detalles', content: DetailModal, size: 'lg' }]}
    />
  );
}
