import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';
import ProductFragments from './fragments/ProductFragments';

export default function QueryPlatformProducts({ children }) {
  return (
    <SmartQuery
      query={gql`
        query PlatformProducts {
          productsPlatform {
            ...ProductFields
          }
        }
        ${ProductFragments.fields}
      `}
      variables={{}}
    >
      {(data) => children(data.productsPlatform || [])}
    </SmartQuery>
  );
}
