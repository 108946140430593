import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Form, FormInput } from 'shards-react';

const UpdateGrossIncomeRetention = ({ mutate, subscription, onSuccess, onError }) => {
  const [grossIncomeRetention, setGrossIncomeRetention] = useState(
    subscription.grossIncomeRetention
  );

  useEffect(() => {
    setGrossIncomeRetention(subscription.grossIncomeRetention);
  }, [subscription]);

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        mutate({
          variables: { id: subscription.id, grossIncomeRetention }
        })
          .then(() => onSuccess())
          .catch((error) => onError(error));
      }}
    >
      <FormGroup>
        <label>Retenciones de IIBB</label>
        <FormInput
          defaultValue={grossIncomeRetention}
          onChange={(event) => {
            const value = parseFloat(event.target.value);
            setGrossIncomeRetention(value);
          }}
          type="number"
          step="0.01"
        />
      </FormGroup>
      <Button type="submit">Actualizar retenciones</Button>
    </Form>
  );
};

export default UpdateGrossIncomeRetention;
