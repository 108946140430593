import React from 'react';
import { Button, Col } from 'shards-react';

const CommentsButton = ({ setOpenCommentBox, className }) => {
  return (
    <Col xs="auto" className={className}>
      <Button
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
        onClick={() => setOpenCommentBox(true)}
        size="lg"
        pill
      >
        Agregar Comentario
      </Button>
    </Col>
  );
};

export default CommentsButton;
