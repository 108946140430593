import gql from 'graphql-tag';

const TaskFragments = {};

TaskFragments.sublistFields = gql`
  fragment TaskSublistFields on Task {
    id
    priority {
      id
      name
    }
    creator {
      id
      email
    }
    assignedTo {
      id
      firstName
      lastName
      email
    }
    dueAt
  }
`;

TaskFragments.fields = gql`
  fragment TaskFields on Task {
    id
    priority {
      id
      name
    }
    assignedTo {
      id
      firstName
      lastName
      email
    }
    creator {
      id
      email
    }
    type {
      id
      name
    }
    resolutionType {
      id
      name
    }
    dueAt
    description
    associatedTo
    taskableType
    taskableId
  }
`;

export default TaskFragments;
