import React, { useState } from 'react';
import { FormGroup, FormInput, Form } from 'shards-react';
import { useHistory } from 'react-router';

const GlobalSearch = () => {
  const [term, setTerm] = useState();
  let history = useHistory();

  const onSubmit = (event) => {
    event.preventDefault();
    history.push(`/search?term=${term || ''}`);
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup className="px-3 mt-3">
        <FormInput type="text" placeholder="Búsqueda" onChange={(e) => setTerm(e.target.value)} />
      </FormGroup>
    </Form>
  );
};

export default GlobalSearch;
