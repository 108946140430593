import React from 'react';
import { Container, Row, Card, CardBody, Alert, Col, Button } from 'shards-react';
import { Link } from 'react-router-dom';

import PageTitle from '../../layouts/PageTitle';
import MutationUpdateTask from '../../mutations/MutationUpdateTask';
import TaskForm from './TaskForm';

export default class EditTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      change_success: false
    };
  }

  successChange = () => {
    this.setState({ change_success: true });
  };

  render() {
    return (
      <Container fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" subtitle="" title={this.props.title} />
          <Col xs="auto" className="ml-3">
            <Link to={`/tasks/${this.props.match.params.id}`}>
              <Button theme="primary" size="sm">
                Ver
              </Button>
            </Link>
          </Col>
        </Row>
        <Card>
          <CardBody>
            {this.state.change_success && (
              <Alert theme="success">La tarea ha sido actualizada.</Alert>
            )}

            <this.props.queryData id={this.props.match.params.id}>
              {(task) => (
                <MutationUpdateTask>
                  {(mutate) => (
                    <TaskForm mutate={mutate} onSubmit={this.successChange} task={task} />
                  )}
                </MutationUpdateTask>
              )}
            </this.props.queryData>
          </CardBody>
        </Card>
      </Container>
    );
  }
}
