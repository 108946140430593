import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export default function QueryTaskTypes({ children }) {
  return (
    <SmartQuery
      query={gql`
        query TaskTypes {
          types: taskTypes {
            id
            name
          }
        }
      `}
      variables={{}}
    >
      {(data) => children(data.types)}
    </SmartQuery>
  );
}
