export default {
  waiting: 'Esperando',
  pending: 'Pendiente',
  rejected: 'Rechazado',
  failed: 'Fallido',
  warning: 'Con advertencias',
  approved: 'Aprobado',
  reversed: 'Revertido',
  other: 'Estado no contemplado'
};
