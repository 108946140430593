import React from 'react';
import { Row, Col, Alert } from 'shards-react';
import { Link } from 'react-router-dom';

const SubscriptionInfo = ({ subscription }) => {
  return (
    <React.Fragment>
      {subscription.updateBlocked && (
        <Alert theme="alert">Debe contactarse con soporte para editar ésta oportunidad</Alert>
      )}
      <Row className="my-2">
        <Col lg="3" sm="6" xs="12">
          Cuit
        </Col>
        <Col lg="9" sm="6" xs="12">
          {subscription.company ? subscription.company.taxId : '-'}
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg="3" sm="6" xs="12">
          Pais
        </Col>
        <Col lg="9" sm="6" xs="12">
          {subscription.country}
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg="3" sm="6" xs="12">
          Cuenta
        </Col>
        <Col lg="9" sm="6" xs="12">
          <Link to={`accounts/${subscription.account.id}`}>{subscription.account.name}</Link>
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg="3" sm="6" xs="12">
          Producto
        </Col>
        <Col lg="9" sm="6" xs="12">
          {subscription.product.name}
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg="3" sm="6" xs="12">
          Partner
        </Col>
        <Col lg="9" sm="6" xs="12">
          {subscription.partner && subscription.partner.name}
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg="3" sm="6" xs="12">
          Contact center
        </Col>
        <Col lg="9" sm="6" xs="12">
          {subscription.contactCenter ? subscription.contactCenter.name : '-'}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SubscriptionInfo;
