import React from 'react';
import gql from 'graphql-tag';

import SmartMutation from './SmartMutation';

import UserFragments from '../queries/fragments/UserFragments';

export default function MutationUpdateUser({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation UpdateUser($id: String!, $phoneNumber: String) {
          user: updateUser(id: $id, phoneNumber: $phoneNumber) {
            ...UserFields
          }
        }
        ${UserFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
