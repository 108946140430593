import React, { useState } from 'react';
import { Container, Row, Card, Col, CardBody, Alert, Button } from 'shards-react';
import { Link } from 'react-router-dom';

import PageTitle from '../../layouts/PageTitle';
import ServerError from '../ServerError';
import UserForm from './UserForm';
import MutationUpdateUser from '../../mutations/MutationUpdateUser';

export default function UserEdit({ title, match, queryData: QueryUser }) {
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [error, setError] = useState();

  const onError = (error) => {
    setChangeSuccess(false);
    setError({ error });
  };

  return (
    <Container fluid>
      <Row className="page-header py-4 xalign-items-center" noGutters>
        <PageTitle className="text-sm-left" subtitle="" title={title} />
        <Col xs="auto">
          <Link to={`/users/${match.params.id}`}>
            <Button theme="primary" size="sm">
              Ver
            </Button>
          </Link>
        </Col>
      </Row>
      <Card>
        <CardBody>
          {changeSuccess && <Alert theme="success">El usuario ha sido actualizado.</Alert>}

          <ServerError error={error} />
          <QueryUser id={match.params.id}>
            {(user) => (
              <MutationUpdateUser>
                {(mutate) => (
                  <UserForm
                    mutate={mutate}
                    onSubmit={() => setChangeSuccess(true)}
                    onError={onError}
                    user={user}
                  />
                )}
              </MutationUpdateUser>
            )}
          </QueryUser>
        </CardBody>
      </Card>
    </Container>
  );
}
