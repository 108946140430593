import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';

export default function MutationSendInvitation({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation SendInvitation($id: String!) {
          response: sendInvitation(id: $id) {
            sent
            invitation {
              acceptedByOther
            }
          }
        }
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
