import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col } from 'shards-react';

const EditButton = ({ id, className }) => {
  if (!id) {
    return null;
  }

  return (
    <Col xs="auto" className={className}>
      <Link to={`${id}/edit`}>
        <Button theme="primary" size="sm">
          Editar
        </Button>
      </Link>
    </Col>
  );
};

export default EditButton;
