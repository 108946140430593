import React from 'react';
import { FormGroup, FormSelect } from 'shards-react';

export const CountrySelect = ({ onChange, required, countries = [] }) => {
  return (
    <FormGroup>
      <label htmlFor="country">País</label>
      <FormSelect
        id="country"
        onChange={(event) => onChange(event.target.value)}
        required={required}
      >
        <option value="">-- Seleccione país --</option>
        {countries.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </FormSelect>
    </FormGroup>
  );
};
