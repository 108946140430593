import React from 'react';
import gql from 'graphql-tag';
import SmartMutation from './SmartMutation';
import TaskFragments from '../queries/fragments/TaskFragments';

export default function MutationCreateTask({ children }) {
  return (
    <SmartMutation
      mutation={gql`
        mutation CreateTask(
          $priorityId: String!
          $taskableType: TaskableType!
          $taskableId: String!
          $typeId: String!
          $assignedToId: String
          $dueAt: DateTime
          $description: String
          $options: TaskOptions
        ) {
          task: createTask(
            priorityId: $priorityId
            taskableType: $taskableType
            taskableId: $taskableId
            typeId: $typeId
            assignedToId: $assignedToId
            dueAt: $dueAt
            description: $description
            options: $options
          ) {
            ...TaskFields
          }
        }
        ${TaskFragments.fields}
      `}
    >
      {(mutate, result) => children(mutate, result)}
    </SmartMutation>
  );
}
